import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {

	// tduUrl = "https://api-v1.telemattica.com/v1";
	dipUrl = "https://dip-app.telemattica.com";
	// dipUrl = "http://localhost:3333";

	constructor() { }
}
