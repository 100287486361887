import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DipService } from '../dip.service';
import { AuthService } from '../auth.service';
import * as c3 from 'c3';

const MONTH_ARRAY = [ "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC" ];

const SLEEP_POWER = 0.01;
const PANEL_DERATING_FACTOR = 0.5;
const NON_CAMERA_POWER = 1;
const CAMERA_POWER = 1.3;
const PANEL_CONVERSION_FACTOR = ( 19 / 1000 ) * PANEL_DERATING_FACTOR;

@Component({
	selector: 'app-location-power-report',
	templateUrl: './location-power-report.component.html',
	styleUrls: ['./location-power-report.component.css'],
})
export class LocationPowerReportComponent implements OnInit {
  loading = false;
  lat = 51.678418;
  lng = 7.809007;

  running_time = 18;
  report_valid = false;
  camera_config = "";

  constructor( private http: HttpClient,
               private authService: AuthService,
               private dipService: DipService ) {}

  async ngOnInit() {
  	await this.authService.authGaurd();
  }

  mapClicked( $event )
  {
  	this.lat = $event.coords.lat;
  	this.lng = $event.coords.lng;
  	this.report_valid = false;
  }

  async generateReport()
  {
  	this.loading = true;

  	const data:any = await this.dipService.getLocationPowerData( this.lat, this.lng );
  
  	console.log( data );

  	this.camera_config = JSON.stringify( data.camera_config, null, 2 );
  	this.report_valid = true;

  	const min_temp_obj = data.properties.parameter[ "T2M_MIN" ];
  	const max_temp_obj = data.properties.parameter[ "T2M_MAX" ];
  	const daylight_obj = data.properties.parameter[ "SG_DAY_HOURS" ];
  	const dirh_obj = data.properties.parameter[ "ALLSKY_SFC_SW_DIRH" ];

  	const min_temp = [];
  	const max_temp = [];
  	const daylight = [];
  	const dirh = [];
  	const p_req = [];
  	const p_0 = [];
  	const p_1 = [];
  	const p_2 = [];

  	const hours_0 = [];
  	const hours_1 = [];
  	const hours_2 = [];

  	for ( let i = 0; i < 12; i++ )
  	{
  		min_temp.push( min_temp_obj[ MONTH_ARRAY[ i ] ] );
  		max_temp.push( max_temp_obj[ MONTH_ARRAY[ i ] ] );
  		daylight.push( daylight_obj[ MONTH_ARRAY[ i ] ] );
  		dirh.push( dirh_obj[ MONTH_ARRAY[ i ] ] );

  		const hours = daylight_obj[ MONTH_ARRAY[ i ] ];
  		const camera_hours = Math.min( hours, this.running_time );
  		const no_camera_hours = Math.max( this.running_time - hours, 0 );
  		const sleep_hours = 24 - ( camera_hours + no_camera_hours );

  		let avg_power = camera_hours * ( CAMERA_POWER + NON_CAMERA_POWER );
  		avg_power += no_camera_hours * NON_CAMERA_POWER;
  		avg_power += sleep_hours * SLEEP_POWER;

  		const power_0aux = PANEL_CONVERSION_FACTOR * 2 * dirh_obj[ MONTH_ARRAY[ i ] ] * 24;
  		const power_1aux = PANEL_CONVERSION_FACTOR * 3 * dirh_obj[ MONTH_ARRAY[ i ] ] * 24;
  		const power_2aux = PANEL_CONVERSION_FACTOR * 4 * dirh_obj[ MONTH_ARRAY[ i ] ] * 24;

  		p_0.push( power_0aux );
  		p_1.push( power_1aux );
  		p_2.push( power_2aux );

  		if ( ( camera_hours * ( CAMERA_POWER + NON_CAMERA_POWER ) ) >= power_0aux )
  		{
  			hours_0.push( power_0aux / ( CAMERA_POWER + NON_CAMERA_POWER ) );
  		}
  		else
  		{
  			hours_0.push( Math.min( ( ( power_0aux - ( camera_hours * ( CAMERA_POWER + NON_CAMERA_POWER ) ) ) / NON_CAMERA_POWER ) + camera_hours, 24 ) );
  		}

  		if ( ( camera_hours * ( CAMERA_POWER + NON_CAMERA_POWER ) ) >= power_1aux )
  		{
  			hours_1.push( power_1aux / ( CAMERA_POWER + NON_CAMERA_POWER ) );
  		}
  		else
  		{
  			hours_1.push( Math.min( ( ( power_1aux - ( camera_hours * ( CAMERA_POWER + NON_CAMERA_POWER ) ) ) / NON_CAMERA_POWER ) + camera_hours, 24 ) );
  		}

  		if ( ( camera_hours * ( CAMERA_POWER + NON_CAMERA_POWER ) ) >= power_2aux )
  		{
  			hours_2.push( power_2aux / ( CAMERA_POWER + NON_CAMERA_POWER ) );
  		}
  		else
  		{
  			hours_2.push( Math.min( ( ( power_2aux - ( camera_hours * ( CAMERA_POWER + NON_CAMERA_POWER ) ) ) / NON_CAMERA_POWER ) + camera_hours, 24 ) );
  		}

  		p_req.push( avg_power );
  	}

  	setTimeout( () => {

  		c3.generate({
  			bindto: '#chart-temperature',
  			data: {
  				columns: [
  					['Min', ... min_temp ],
  					['Max', ... max_temp ],
  				]
  			},
  			axis: {
  				x: {
  					type: 'category',
  					categories: MONTH_ARRAY
  				}
  			}
  		});
  
  		c3.generate({
  			bindto: '#chart-daylight',
  			data: {
  				columns: [
  					['Daylight Hours', ... daylight ],
  					['0 Aux Panel Running Hours', ... hours_0 ],
  					['1 Aux Panel Running Hours', ... hours_1 ],
  					['2 Aux Panel Running Hours', ... hours_2 ],
  				]
  			},
  			axis: {
  				x: {
  					type: 'category',
  					categories: MONTH_ARRAY
  				}
  			}
  		});

  		c3.generate({
  			bindto: '#chart-dirh',
  			data: {
  				columns: [
  					['W/m2', ... dirh ],
  				]
  			},
  			axis: {
  				x: {
  					type: 'category',
  					categories: MONTH_ARRAY
  				}
  			}
  		});

  		c3.generate({
  			bindto: '#chart-power',
  			data: {
  				columns: [
  					['Required Wh/d', ... p_req ],
  					['0 Aux Panels Wh/d', ... p_0 ],
  					['1 Aux Panels Wh/d', ... p_1 ],
  					['2 Aux Panels Wh/d', ... p_2 ],
  				]
  			},
  			axis: {
  				x: {
  					type: 'category',
  					categories: MONTH_ARRAY
  				}
  			}
  		});

  	}, 1 );

  	this.loading = false;
  }

  downloadCameraConfig( ) 
  {
  	const element = document.createElement('a');
  	element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent( this.camera_config ));
  	element.setAttribute('download', "config.json");
  
  	element.style.display = 'none';
  	document.body.appendChild(element);
  
  	element.click();
  
  	document.body.removeChild(element);
  }
}
