<div class="grid" style="overflow:auto;">
    <div class="grid-main-view">
        <h3>% Alerts reviewed by org</h3>
        <div id="percentChart"></div>

        <h3>User alert reviews</h3>
        Org: <select id="orgSelect" (change)="changed_org_plot( $event.target.value )" >
            <option *ngFor="let org of orgs">{{org.name}}</option>
        </select> Total: {{org_total}}

        <div id="userChart"></div>

        <h3>Alert view histogram</h3>

        Org: <select (change)="changed_hist_org( $event.target.value )" >
            <option>All</option>
            <option *ngFor="let org of orgs">{{org.name}}</option>
        </select>

        <div id="histChart"></div>

        <h3>Mean time to review</h3>

        <div id="timeChart"></div>

        <br><br>
    </div>

    <div class="grid-side-bar">
        Start: <input id="startDate" type="datetime-local"><br>
        End: <input id="endDate" type="datetime-local"><br><br>
        <button mat-raised-button color="primary" (click)="refresh()">LOAD</button>
    </div>
</div>
<div *ngIf="loading" class="loading-overlay" >
    <mat-spinner></mat-spinner>
</div>