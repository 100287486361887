import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth.service';  
import { DipService } from '../dip.service';
import { TeleDate } from '../time-helprs';
import * as c3 from 'c3';

@Component({
	selector: 'app-overview',
	templateUrl: './overview.component.html',
	styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

	organisation = "all";
	startDate: string;
	endDate: string;
	loading = false;
	organisations = [];
	gateways = [];
	gateway_map = {};

	data:any = {};

	constructor( private authService: AuthService,
                 private dipService: DipService,
				 private snackBar: MatSnackBar ) 
	{ }

	async ngOnInit() {
		await this.authService.authGaurd();

		this.loading = true;

		try
		{
			this.organisations = ( await this.dipService.getOrganisations() ).map( o => o.name );
			this.gateways = await this.dipService.getGateways();

			for ( const gw of this.gateways )
			{
				this.gateway_map[gw.hwAddress] = gw;
			}

			this.organisations.push( "all" );
			this.startDate = (new TeleDate()).adjustLocal().addHours( -24 * 7 ).toDateTimeLocalString();
			this.endDate = (new TeleDate()).adjustLocal().toDateTimeLocalString();
		}
		catch( err )
		{
			console.error( err );
			this.snackBar.open( "Error occurred check console for details", "close" );
		}

		this.refresh();	
	}

	async refresh()
	{
		this.loading = true;

		try
		{
			this.data = await this.dipService.getOverviewReportData( new Date( this.startDate ), new Date( this.endDate ) );

			console.log( this.data );

			this.update_plots();
		}
		catch( err )
		{
			console.error( err );
			this.snackBar.open( "Error occurred check console for details", "close" );
		}
		
		this.loading = false;
	}

	update_plots()
	{
		this.update_power_plot();
		this.update_events_plot();
		this.update_distance_plot();
	}

	update_power_plot()
	{
		this.data.power_levels.sort( ( a, b ) => { 
			if( a.owner < b.owner ) { return -1; }
			if( a.owner > b.owner ) { return 1; }
			return 0;
		} );

		let data = this.data.power_levels;
		if ( this.organisation !== "all" )
		{
			data = data.filter( d => d.owner === this.organisation );
		}

		c3.generate({
			bindto: '#chart-power',
			data: {
				columns: [ 
					[ 'power', ... data.map( p => p.power_level ) ]
				 ],
				type: 'bar'
			},
			axis: {
				x: {
					type: 'category',
					categories: [ ... data.map( this.generate_data_label.bind(this) ) ]
				},
				y: {
					min: 0,
					max: 100
				}
			},
			legend: {
				show: false
			}
		});
	}

	update_events_plot()
	{
		this.data.event_summary.sort( ( a, b ) => { 
			if( a.owner < b.owner ) { return -1; }
			if( a.owner > b.owner ) { return 1; }
			return 0;
		} );

		let data = this.data.event_summary;
		if ( this.organisation !== "all" )
		{
			data = data.filter( d => d.owner === this.organisation );
		}

		c3.generate({
			bindto: '#chart-events',
			data: {
				columns: [ 
					[ 'total', ... data.map( v => v.total ) ],
					[ 'accel', ... data.map( v => v.accel_count ) ],
					[ 'video', ... data.map( v => v.video_count ) ],
				 ],
				type: 'bar'
			},
			axis: {
				x: {
					type: 'categories',
					categories: [ ... data.map( this.generate_data_label.bind(this) ) ]
				}
			},
			grid: {
				y: {
					lines: [{value:0}]
				}
			},
			legend: {
				position: 'right'
			}
		});
	}

	update_distance_plot()
	{
		this.data.distance_travelled.sort( ( a, b ) => { 
			if( a.owner < b.owner ) { return -1; }
			if( a.owner > b.owner ) { return 1; }
			return 0;
		} );

		let data = this.data.distance_travelled;
		if ( this.organisation !== "all" )
		{
			data = data.filter( d => d.owner === this.organisation );
		}

		c3.generate({
			bindto: '#chart-distance',
			data: {
				columns: [ 
					[ 'dist', ... data.map( p => p.distance ) ]
				 ],
				type: 'bar'
			},
			axis: {
				x: {
					type: 'category',
					categories: [ ... data.map( this.generate_data_label.bind(this) ) ]
				}
			},
			legend: {
				show: false
			}
		});
	}

	generate_data_label( vehicle )
	{
		if ( this.organisation === 'all' )
		{
			return `${vehicle.owner} - ${vehicle.vehicle_id}`;
		}
		else
		{
			return vehicle.vehicle_id;
		}
	}
}


