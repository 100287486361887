<div class="grid">
    <div class="grid-main-view">
        <agm-map 
            (mapClick)="mapClicked($event)" 
            [latitude]="lat" 
            [longitude]="lng"
        >
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>

        <div [hidden]="!report_valid">
            <h2>Running Hours</h2>
            <div class="chart" id="chart-daylight"></div>
            <!-- <h2>Power Requirements</h2>
            <div class="chart" id="chart-power"></div> -->
            <h2>Temperature Range</h2>
            <div class="chart" id="chart-temperature"></div>
            <h2>Solar Irridiance</h2>
            <div class="chart" id="chart-dirh"></div>
        </div>
        

    </div>

    <div class="grid-side-bar">
        <button mat-raised-button color="primary" (click)="generateReport()">GENERATE REPORT</button><br><br>
        <button [disabled]="!report_valid" mat-raised-button color="primary" (click)="downloadCameraConfig()">GENERATE CAMERA CONFIG</button>
    </div>
</div>

<div *ngIf="loading" class="loading-overlay" >
    <mat-spinner></mat-spinner>
</div>