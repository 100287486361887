<div class="grid">
    <div class="grid-center-1x1">
        <mat-card>
            <form>
                Username: <input type="text" [(ngModel)]="username" name="username"><br>
                Password: <input type="password" [(ngModel)]="password" name="password"><br>
                Token: <input autocomplete="off" type="text" [(ngModel)]="token" name="token"><br>
                <div class="login-button">
                    <button mat-raised-button color="primary" (click)="login()">LOGIN</button>
                </div>
                <p style="color:red">{{error_message}}</p>
            </form>
        </mat-card>
    </div>
</div>
