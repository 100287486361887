import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { DipService } from '../dip.service';

@Component({
	selector: 'app-change-manager',
	templateUrl: './change-manager.component.html',
	styleUrls: ['./change-manager.component.css']
})
export class ChangeManagerComponent implements OnInit {

	changes = [];
	error_message = "";
	loading = false;

	constructor( private authService: AuthService,
               private dipService: DipService,
               private router: Router ) { }

	async ngOnInit(): Promise<any> {
  	await this.authService.authGaurd();
  	this.changes = this.dipService.getStagedChanges();
	}

	async executeChanges()
	{
  	let error = false;

  	this.loading = true;

  	try
  	{
  		console.log( await this.dipService.executeChanges( this.changes ) );
  	}
  	catch( err )
  	{
  		console.error( err );
  		this.error_message = "Error occurred during updates, refer to console for details";
  		error = true;
  	}

  	this.loading = false;

  	if ( error == false )
  	{
  		this.error_message = "Updates succeeded";
  		this.changes = [];
  		this.dipService.stageChanges([]);
  	}
	}

	rejectChanges()
	{
  	this.dipService.stageChanges([]);
  	this.changes = [];
	}

	removeChangeItem( change_index )
	{
  	this.changes.splice( change_index, 1 );
  	this.dipService.stageChanges( this.changes );
	}

}
