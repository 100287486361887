<div class="grid" style="overflow:auto;">
    <div class="grid-main-view" style="overflow-x:hidden;">
        <div *ngIf="isGen3">
            <h3>Battery Charge</h3>
        </div>
        <div id="powerChart"></div>
        <h3>Solar Voltage</h3>
        <div id="solarChart"></div>
        <h3>Battery Voltage</h3>
        <div id="batteryChart"></div>
        <div *ngIf="isGen3">
            <h3>Power Flow</h3>
        </div>
        <div id="rawPowerChartTemp"></div>
    </div>

    <div class="grid-side-bar">
        Gateway: <select id="hwAddress"></select><br>
        Start: <input id="startDate" type="datetime-local" [value]="startDate"><br>
        End: <input id="endDate" type="datetime-local" [value]="endDate"><br>
        <button mat-raised-button color="primary" (click)="refresh()">REFRESH</button>
    </div>
</div>

<div *ngIf="loading" class="loading-overlay" >
    <mat-spinner></mat-spinner>
</div>