<div class="header" *ngIf="router.url !== '/login'">
    
    <button mat-button [matMenuTriggerFor]="menu">Menu</button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="monitoring">MONITORING</button>
        <button mat-menu-item [matMenuTriggerFor]="management">MANAGEMENT</button>
        <button mat-menu-item [matMenuTriggerFor]="reports">REPORTS</button>
    </mat-menu>

    <mat-menu #monitoring="matMenu">
        <button mat-menu-item [routerLink]="['/issues']">ISSUES</button>
        <button mat-menu-item [routerLink]="['/overview']">OVERVIEW</button>
        <button mat-menu-item [routerLink]="['/alerts']">ALERTS</button>
        <button mat-menu-item [routerLink]="['/logs']">LOGS</button>
        <button mat-menu-item [routerLink]="['/power']">POWER</button>
        <button mat-menu-item [routerLink]="['/vehicles']">VEHICLES STATUS</button>
    </mat-menu>

    <mat-menu #management="matMenu">
        <button mat-menu-item [routerLink]="['/organisation-admin']">ORGANISATION ADMIN</button>
        <button mat-menu-item [routerLink]="['/devices']">DEVICE SETTINGS</button>
        <button mat-menu-item [routerLink]="['/commands']">COMMANDS</button>
        <button mat-menu-item [routerLink]="['/config-manager']">CONFIGS</button>
        <button mat-menu-item [routerLink]="['/changes']">CHANGE MANAGER</button>
    </mat-menu>

    <mat-menu #reports="matMenu">
        <button mat-menu-item [routerLink]="['/user-report']">ALERT VIEWS</button>
        <button mat-menu-item [routerLink]="['/location-power-report']">LOCATION POWER REPORT</button>
    </mat-menu>

    <button mat-raised-button color="primary" (click)="logout()">LOGOUT</button>
</div>

<router-outlet></router-outlet>