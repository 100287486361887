import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';  
import { DipService } from '../dip.service';

@Component({
	selector: 'app-logs',
	templateUrl: './logs.component.html',
	styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {

	chartData: any = [];
	chartOptions = {
  	hAxis: {
  		format: 'dd-MM-yyyy:HH-MM',
  		slantedText: true,
  		slantedTextAngle: 45
  	}
	}
	startDate: string;
	endDate: string;
	loading = false;

	constructor( private authService: AuthService,
               private dipService: DipService ) { }

	async ngOnInit() {
  	await this.authService.authGaurd();

  	const start = <HTMLInputElement>document.getElementById( "startDate" );
  	const end = <HTMLInputElement>document.getElementById( "endDate" );

  	// @ts-ignore
  	start.value = (new Date()).adjustLocal().addHours(-24).toDateTimeLocalString();
  	// @ts-ignore
  	end.value = (new Date()).adjustLocal().toDateTimeLocalString();

  	const gateways = await this.dipService.getGateways();

  	console.log( gateways );

  	const x = document.getElementById("hwAddress");
  	// @ts-ignore
  	x.options.length = 0;
  	gateways.forEach((gateway) => {
  		const option = document.createElement("option");
  		option.text = gateway.hwAddress.toString(16).toUpperCase();
  		option.value = gateway.hwAddress;
  		// @ts-ignore
  		x.add(option);
  	});
	}

	async refresh() {
  	this.loading = true;

  	try {
  		const hwAddress = (<HTMLInputElement>document.getElementById('hwAddress')).value;
  		const startDate = new Date((<HTMLInputElement>document.getElementById("startDate")).value);
  		const endDate = new Date((<HTMLInputElement>document.getElementById("endDate")).value);
  
  		const logs = await this.dipService.getSystemLogs( hwAddress, startDate, endDate );
  
  		this.chartData = logs.map( (a) => {
  			return [ a[0], a[1], new Date( a[2] ), new Date( a[3] ) ];
  		} );
  	} catch ( err ) {
  		console.error( err );
  	}
    
  	this.loading = false;
	}
}
