import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';  

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
	username = "";
	password = "";
	token = "";
	error_message = "";

	constructor( private authService: AuthService,
               private router: Router ) 
	{ }

	async ngOnInit() {
  	if ( await this.authService.isAuthenticated() )
  	{
  		this.router.navigate( [ 'issues' ] );
  	}
	}

	async login() {
  	this.error_message = "";
    
  	if ( await this.authService.login( {
  		username: this.username,
  		password: this.password,
  		token: this.token
  	} ) )
  	{
  		this.router.navigate( [ 'issues' ] );
  	}
  	else
  	{
  		this.error_message = "Login error, check credentials";
  	}
    
	}
}
