import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { HotTableRegisterer } from '@handsontable/angular';
import { AuthService } from '../auth.service';
import { DipService } from '../dip.service';
import Handsontable from 'handsontable';
import { Router } from '@angular/router';

const edit_operations = [ "edit", "CopyPaste.paste", "UndoRedo.undo", "UndoRedo.redo" ];

@Component({
	selector: 'telemattica-config-manager',
	templateUrl: './config-manager.component.html',
	styleUrls: ['./config-manager.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class ConfigManagerComponent implements OnInit {

	private hot = new HotTableRegisterer();
  
	loading = false;
	showView = true;
	showDetails = false;
	timeoffset = 0;

	detailedConfigData = "";

	tableSettings = {
  	data: [],
  	colWidths: 100,
  	width: '100%',
  	height: '100%',
  	rowHeights: 23,
  	rowHeaders: true,
  	colHeaders: true,
  	dropdownMenu: true,
  	filters: true,
  	columnSorting: true,
		contextMenu: {
  		callback: ( key, selection, clickEvent ) => {
  			// Common callback for all options
  			// console.log( key, selection, clickEvent );
				const configs = [];

  			switch( key )
  			{
  			case "details":
  				

  				for ( const sel of selection )
  				{
  					for ( let i = sel.start.row; i <= sel.end.row; i++ )
  					{
  						configs.push( this.hot.getInstance( this.id ).getSourceDataAtRow( this.hot.getInstance( this.id ).toPhysicalRow( i ) ) );
  					}
  				}

					if ( configs.length !== 1 )
					{
						console.error( "Detail view can only load one config at a time" );
					}
					else
					{
						this.showDetailsView( configs[0] );
					}
		
					break;

  			default:
  				console.log( "Unknown contextual command" );
  				break;
  			}
  		},
  		items: {
  			"details": {
  				name: "View config details"
  			}
  		}
  	},
  	columns: [
  		{
  			data: '_id',
  			title: "ID",
  			width: '200px',
  			readOnly: true
  		},
  		{
  			data: 'device',
  			title: "Device Type",
  			width: '100px',
  			readOnly: true
  		},
			{
  			data: 'version',
  			title: "Version",
  			width: '100px',
  			readOnly: true
  		},
			{
  			data: 'status',
  			title: "Status",
  			width: '150px',
  			type: "dropdown",
  			source: [ "ACTIVE", "DEPRECATED", "EXPERIMENTAL", "RETIRED" ]
  		},
			{
  			data: 'description',
  			title: "Description",
  			width: '500px'
  		}
  	]
	};

	id = "tableInstance";

	configs: Array<any> = [];
	changes = {};

	constructor( private authService: AuthService,
               private dipService: DipService,
               private cd: ChangeDetectorRef,
               private router: Router ) { }

	async ngOnInit() {
		await this.authService.authGaurd();

		Handsontable.hooks.add( 'beforeChange', ( changes:any, type ) => { 
  		if ( changes )
  		{
  			for ( let i = 0; i < changes.length; i++ )
  			{
  				if ( edit_operations.includes( type ) ) 
  				{ 
  					if ( changes[i][1] == "checked" || ( changes[i][2] == changes[i][3] ) )
  					{
  						continue;
  					}

  					const changed:any = this.hot.getInstance( this.id ).getSourceDataAtRow( this.hot.getInstance( this.id ).toPhysicalRow( changes[i][0] ) );

  					if ( typeof this.changes[ changed._id ] == typeof undefined )
  					{
  						this.changes[ changed._id ] = {};
  					}
            
  					this.changes[ changed._id ][ changes[i][1] ] = [ changes[i][2], changes[i][3] ];
  				} 
  			}

  			console.log( this.changes );
  		}
  	} );

		this.configs = await this.dipService.getConfigs();

		this.configs = this.configs.sort( sortByDeviceThenVersion );

		this.configs = this.configs.map( ( a ) => { 
			if ( a.status )
				return a;

			a.status = "ACTIVE";

			return a;
		} );

		console.log( this.configs );

		this.hot.getInstance( this.id ).loadData( this.configs );
	}

	showDetailsView( config )
	{
		this.showView = false;
		this.showDetails = true;

		console.log( config );

		const tod_devices = [ "GW2000", "GW1000", "SU1220_DIP", "SU1210_DIP" ];

		this.detailedConfigData = formatStandardConfigHtml( config );

		if ( tod_devices.includes( config.device ) )
		{
			this.detailedConfigData += formatTODConfigHtml( config );
		}

		this.cd.detectChanges();
	}

	showTable()
	{
		this.showView = true;
		this.showDetails = false;
		this.detailedConfigData = "";
		this.cd.detectChanges();
		this.hot.getInstance( this.id ).loadData( this.configs );
	}

	reviewChanges( )
	{

  	const changes = [];

  	for ( const config in this.changes )
  	{
  		for ( const change in this.changes[config] )
  		{
  			let temp;

  			switch( change )
  			{
  			case "description":
  			case "status":
            
  				temp = { 
  					operation: 'updateOne',
  					query: { _id: config },
  					update: { $set: { } },
  					description: `Change config ${this.getConfigFromId(config).device} v${this.getConfigFromId(config).version} - ${change} from ${this.changes[config][change][0]} to ${this.changes[config][change][1]}`,
  					database: 'dip',
  					collection: 'configurations'
  				};

  				temp.update.$set[change] = this.changes[config][change][1];

  				break;
  			}

  			changes.push( temp );
  		}
  	}

		this.dipService.stageChanges( changes );
  	this.router.navigateByUrl( '/changes' );
	}

	getConfigFromId( id:string )
	{
		return this.configs.filter( a => a._id === id )[0];
	}
}

function formatStandardConfigHtml( config ): string
{
	return `
  <div class="config-details">
  <h2>Config</h2>
  <strong>Type: </strong>${config.device}<br>
  <strong>Version: </strong>${config.version}<br>
  <strong>Description: </strong>${config.description || ""}<br>
  </div>

  <div class="config-details">
  <h2>General Settings</h2>
    ${formatNonTimeFields( config )}
  </div>`;
}

function formatTODConfigHtml( config ): string
{
	return `  
  <div class="config-details">
  <h2>Time of day power settings (UTC)</h2>
  ${formatTimeOfDayArrays(config.values)}
  </div>
  
  `;
}

function formatNonTimeFields( config )
{
	let html = "";
	const tod_fields = [ "wakeTimeHour", "wakeTimeMinute", "sleepTimeHour", "sleepTimeMinute" ];

	for ( const prop in config.values )
	{
		if( tod_fields.includes(prop) === false )
		{
			html += `<strong>${prop}: </strong>${config.values[prop]}<br>\n`;
		}
	}

	return html;
}

function formatTimeOfDayArrays( configValues: any ): string
{
	/*
  "wakeTimeHour": "[0,16,18,21,23,0,0,23,21,18,0,0]",
  "wakeTimeMinute": "[0,0,0,0,0,0,0,0,0,0,0,0]",
  "sleepTimeHour": "[0,12,10,9,7,6,6,7,9,10,0,0]",
  "sleepTimeMinute": "[0,0,0,0,0,0,0,0,0,0,0,0]"
  */

	if ( !configValues.wakeTimeHour || !configValues.wakeTimeHour || !configValues.wakeTimeHour || !configValues.wakeTimeHour )
	{
		return "<strong>No time of data configuration present, defaults to always on</strong>";
	}

	let html = `
  <table>
  <tr>
    <th></th>
    <th>Jan</th>
    <th>Feb</th>
    <th>Mar</th>
    <th>Apr</th>
    <th>May</th>
    <th>Jun</th>
    <th>Jul</th>
    <th>Aug</th>
    <th>Sep</th>
    <th>Oct</th>
    <th>Nov</th>
    <th>Dec</th>
  </tr>`;

	html += '<tr><td>Wake Time</td>';
	for ( let i = 0; i < 12; i++ )
	{
		if ( configValues.wakeTimeHour[i] >= 12 )
		{
			html += `<td>${configValues.wakeTimeHour[i] - 12}:${configValues.wakeTimeMinute[i].toString().padStart(2,'0')}pm</td>`;
		}
		else
		{
			html += `<td>${configValues.wakeTimeHour[i]}:${configValues.wakeTimeMinute[i].toString().padStart(2,'0')}am</td>`;
		}
	}
	html += '</tr>';

	html += '<tr><td>Sleep Time</td>';
	for ( let i = 0; i < 12; i++ )
	{
		if ( configValues.sleepTimeHour[i] >= 12 )
		{
			html += `<td>${configValues.sleepTimeHour[i] - 12}:${configValues.sleepTimeMinute[i].toString().padStart(2,'0')}pm</td>`;
		}
		else
		{
			html += `<td>${configValues.sleepTimeHour[i]}:${configValues.sleepTimeMinute[i].toString().padStart(2,'0')}am</td>`;
		}
	}
	html += '</tr>';

	html += '</table>';

	return html;
}

function sortByDeviceThenVersion( a, b ) {
	if ( a.device < b.device )
	{
		return -1;
	}
	else if ( a.device > b.device )
	{
		return 1;
	}
	else
	{
		if ( a.version < b.version )
		{
			return -1;
		}
		else
		{
			return 1;
		}
	}
}