import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	loggedIn = false;

	accessToken = "";
	refreshTimerStarted = false;

	constructor( private router: Router,
               private http: HttpClient,
               private config: ConfigService ) 
	{ }

	async isAuthenticated()
	{
  	if ( this.loggedIn === false && localStorage.getItem( "dipRefreshToken" ) )
  	{
  		this.loggedIn = await this.refreshDipToken();
  	}

  	if ( this.loggedIn )
  	{
  		return true;
  	}

  	return false;
	}

	refreshDipToken(): Promise<boolean>
	{
  	return new Promise( ( resolve ) => {
  		this.http.post( this.config.dipUrl + "/refresh", {
  			refresh_token: localStorage.getItem( "dipRefreshToken" )
  		} ).subscribe( ( data: any ) => {
  			this.loggedIn = true;
  			this.accessToken = data.access_token;

  			console.log( "Got DIP access token using refresh token" );

  			if ( this.refreshTimerStarted === false )
  			{
  				setInterval( this.refreshDipToken.bind(this), ( data.expires_in * 1000 / 2 ) );
  				this.refreshTimerStarted = true;
  			}

  			resolve( true );
  		}, ( error ) => { 
  			console.log( error.status );
  			console.log( error.message );

  			resolve( false );
  		} );
  	} );
	}

	async authGaurd()
	{
  	if ( await this.isAuthenticated() === false )
  	{
  		this.router.navigate( [ 'login' ] );
  	}
	}

	login( creds: any )
	{
  	return new Promise( (resolve) => {
  		this.http.post( this.config.dipUrl + "/login", creds ).subscribe( ( data: any ) => {
  			localStorage.setItem( "dipRefreshToken", data.refresh_token );
  			this.loggedIn = true;
  			this.accessToken = data.access_token;

  			if ( this.refreshTimerStarted === false )
  			{
  				setInterval( this.refreshDipToken.bind(this), ( data.expires_in * 1000 / 2 ) );
  				this.refreshTimerStarted = true;
  			}

  			resolve( true );
  		}, ( error ) => { 
  			console.log( error.status );
  			resolve( false );
  		} );
  	} );
    
	}

	logout()
	{
  	localStorage.removeItem( "dipRefreshToken" );
  	this.loggedIn = false;
  	this.accessToken = "";
  	this.router.navigate( [ 'login' ] );
	}
}
