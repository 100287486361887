<div class="grid">
    <div class="grid-main-view">
        <google-chart type="Timeline" [options]="chartOptions" [data]="chartData" style="height: 100%; width:99%; padding: 5px;"></google-chart>
    </div>

    <div class="grid-side-bar">
        Gateway: <select id="hwAddress"></select><br>
        Start: <input id="startDate" type="datetime-local" [value]="startDate"><br>
        End: <input id="endDate" type="datetime-local" [value]="endDate"><br>
        <button mat-raised-button color="primary" (click)="refresh()">REFRESH</button>
    </div>
</div>

<div *ngIf="loading" class="loading-overlay" >
    <mat-spinner></mat-spinner>
</div>