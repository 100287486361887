

class TeleDate extends Date
{
	constructor( v? )
	{
		if ( v )
		{
			super( v );
		}
		else
		{
			super( );
		}
	}

	addHours( hours:number ) 
	{
		this.setTime( this.getTime() + ( hours * 60 * 60 * 1000 ));

		return this;
	}

	adjustLocal() 
	{
		this.setTime( this.getTime() - (( this.getTimezoneOffset() / 60 ) * 60 * 60 * 1000 ) );

		return this;
	}

	toDateTimeLocalString()
	{
		return this.toISOString().slice( 0, 16 );
	}

	secondsToNow()
	{
		return ( this.getTime() - (new Date()).getTime() ) / 1000;
	}
}

export { TeleDate };
