import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';  
import { DipService } from '../dip.service';
import { HotTableRegisterer } from '@handsontable/angular';
import { Router } from '@angular/router';

import Handsontable from 'handsontable';

function hw_address_renderer( instance, td, row, col, prop, value, cellProperties )
{
	td.innerHTML = `${value} (0x${value.toString(16)})`;

	return td;
}

function stringyFirmwareToId( stringy_version )
{
	if ( typeof stringy_version === typeof 100 )
	{
		return stringy_version;
	}

	try
	{
		const fields = stringy_version.split( '_' )[1].split( '.' );

		if ( stringy_version.split( '_' )[0] === 's' )
		{
			return ( 0x80000000 | ( parseInt( fields[0] ) << 24 ) | ( parseInt( fields[1] ) << 16 ) | ( parseInt( fields[2] ) ) ) >>> 0;
		}

		return ( parseInt( fields[0] ) << 24 ) | ( parseInt( fields[1] ) << 16 ) | ( parseInt( fields[2] ) );
	}
	catch ( err )
	{
		console.error( err );
		console.log( stringy_version );
	}

	return 0;
}

function convertIdToStringyVersion( id, forceSafe?:boolean ){
	let str = "";

	if ( id === 0 || id == null )
	{
		return "UNKNOWN";
	}

	if( 0x80000000 & id || forceSafe ){    // safe
		str = `s_${(id & 0x7F000000) >> 24}.${(id & 0x00FF0000) >> 16}.${(id & 0x0000FFFF)}`;
	} else {        // Prod
		str = `p_${(id & 0x7F000000) >> 24}.${(id & 0x00FF0000) >> 16}.${(id & 0x0000FFFF)}`;
	}

	return str;
}

function firmware_renderer( instance, td, row, col, prop, value, cellProperties ) {

	if ( value <= 0 || typeof value !== typeof 100 || isNaN( value ) )
	{
		td.innerHTML = "UN-CONFIGURED";
		td.style.color = "red";
	}
	else
	{
		td.innerHTML = convertIdToStringyVersion( value );
	}

	return td;
}

function config_renderer(instance, td, row, col, prop, value, cellProperties) {

	if ( value <= 0 || typeof value !== typeof 100 || isNaN( value ) )
	{
		td.innerHTML = "UN-CONFIGURED";
		td.style.color = "red";
	}
	else
	{
		td.innerHTML = value;
	}

	return td;
}

const edit_operations = [ "edit", "CopyPaste.paste", "UndoRedo.undo", "UndoRedo.redo", "Autofill.fill" ];
const numbers_fields = [ "configuration.version" ];  
const firmware_fields = [ "firmware.prod.version", "firmware.safe.version" ];

@Component({
	selector: 'app-devices',
	templateUrl: './devices.component.html',
	styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {

	private hot = new HotTableRegisterer();

	columnDefs = [
  	{ field: 'hwAddress', sortable: true, filter: true },
  	{ field: 'type', sortable: true, filter: true },
  	{ field: 'serialNo', sortable: true, filter: true }
	];

	id = 'tableInstance';

	loading = false;
	config_device_type = "MORE THAN 1 TYPE SELECTED";

	rowData = [];

	devices: any;

	organisations = [];

	configs = {
  	"SU1210_DIP": [],
  	"SU1210_PI": [],
  	"SU1220_DIP": [],
  	"SU1220_PI": [],
  	"GW1000": [],
  	"GW2000": [],
  	"SU1000": [],
  	"PM1010": []
	};

	firmwaresSafe = {
  	"SU1210_DIP": [],
  	"SU1210_PI": [],
  	"SU1220_DIP": [],
  	"SU1220_PI": [],
  	"GW1000": [],
  	"GW2000": [],
  	"SU1000": [],
  	"PM1010": []
	};

	firmwaresProd = {
  	"SU1210_DIP": [],
  	"SU1210_PI": [],
  	"SU1220_DIP": [],
  	"SU1220_PI": [],
  	"GW1000": [],
  	"GW2000": [],
  	"SU1000": [],
  	"PM1010": []
	};

	device_types = [ "SU1210", "SU1220", "GW1000", "GW2000", "SU1000", "PM1010" ];

	new_device_type = "";
	new_device_count = 0;

	tableSettings = {
  	data: [],
  	colWidths: 100,
  	width: '100%',
  	height: '100%',
  	rowHeights: 23,
  	rowHeaders: true,
  	colHeaders: true,
  	dropdownMenu: true,
  	filters: true,
  	cells: this.cellEditor.bind(this),
  	columnSorting: true,
  	columns: [
  		{
  			data: 'hwAddress',
  			title: "HW Address",
  			width: '150px',
  			renderer: hw_address_renderer,
  			readOnly: true
  		},
  		{
  			data: 'serialno',
  			title: "Serial Number",
  			width: '150px',
  			readOnly: true
  		},
  		{
  			data: 'type',
  			title: "Model",
  			width: '150px',
  			readOnly: true
  		},
			{
				data: 'vehicle_id',
				title: "Vehicle",
				width: '150px',
				readOnly: true
			},
  		{
  			data: 'configuration.version',
  			title: "Config",
  			width: '150px',
  			renderer: config_renderer
  		},
  		{
  			data: 'firmware.prod.version',
  			title: "firmware prod",
  			width: '150px',
  			renderer: firmware_renderer
  		},
  		{
  			data: 'firmware.safe.version',
  			title: "firmware safe",
  			width: '150px',
  			renderer: firmware_renderer
  		},
  		{
  			data: 'tdp.properties.owner',
  			title: "TDP Prod Owner",
  			width: '150px',
  			type: "dropdown",
  			source: (function( q, c ){ c( this.organisations ); }).bind(this)
  		},
  		{
  			data: 'beta.properties.owner',
  			title: "TDP Beta Owner",
  			width: '150px',
  			type: "dropdown",
  			source: (function( q, c ){ c( this.organisations ); }).bind(this)
  		},
  		{
  			data: 'status',
  			title: "Device Status",
  			width: '150px',
  			type: "dropdown",
  			source: [ 'OPERATIONAL', 'RETIRED', 'STOCK', 'EXPERIMENTAL', 'TESTING' ]
  		}
  	]
	}

	changes = {};
	include_retired = false;

	constructor( private authService: AuthService,
               private dipService: DipService,
               private router: Router ) 
	{
    
	}

	async ngOnInit() {

  	Handsontable.hooks.add( 'beforeChange', ( changes:any, type ) => { 
  		if ( changes )
  		{
  			for ( let i = 0; i < changes.length; i++ )
  			{
				  console.log( type );
  				if ( edit_operations.includes( type ) ) 
  				{ 
  					if ( changes[i][1] == "checked" || ( changes[i][2] == changes[i][3] ) )
  					{
  						continue;
  					}

  					const changed = this.hot.getInstance( this.id ).getSourceDataAtRow( this.hot.getInstance( this.id ).toPhysicalRow( changes[i][0] ) );

  					if ( numbers_fields.includes( changes[i][1] ) )
  					{
  						changes[i][3] = parseInt( changes[i][3] );
  					}
  					else if ( firmware_fields.includes( changes[i][1] ) )
  					{
  						changes[i][3] = stringyFirmwareToId( changes[i][3] );
  					}
  
  					// console.log( changes ); 
  					// console.log( changed );

  					// @ts-ignore
  					if ( typeof this.changes[ changed.hwAddress ] == typeof undefined )
  					{
  						// @ts-ignore
  						this.changes[ changed.hwAddress ] = {};
  					}
            
  					// @ts-ignore
  					this.changes[ changed.hwAddress ][ changes[i][1] ] = [ changes[i][2], changes[i][3] ];
  				} 
  			}

  			// console.log( this.changes );
  		}
  	} );

  	const x = document.getElementById( "device_type" );
  	// @ts-ignore
  	x.options.length = 0;
  	this.device_types.forEach((type) => {
  		const option = document.createElement( "option" );
  		option.text = type;
  		option.value = type;
  		// @ts-ignore
  		x.add(option);
  	});

  	this.loading = true;

  	await this.authService.authGaurd();

  	this.devices = (<Array<any>>( await this.dipService.getDevices( this.include_retired ) )).sort( ( a, b ) => { return a.hwAddress - b.hwAddress; } );
  	this.organisations = (<Array<any>>( await this.dipService.getOrganisations() )).map( a => a.name );

  	const configs = (<Array<any>>( await this.dipService.getConfigs() ));
  	const firmwares = (<Array<any>>( await this.dipService.getFirmwares() ));

		let systems = await this.dipService.getVehicles();
		systems = systems.filter( s => s.gateway );

		console.log( systems );

		const vehicle_id_map = {};

		for ( const system of systems )
		{
			if ( typeof system.devices === typeof undefined )
			{
				continue;
			}
			
			for ( const entry of Object.entries( system.devices ) )
			{
				const hw_address:number = parseInt( entry[ 0 ] );

				vehicle_id_map[ hw_address ] = system.vehicle_id;
			}
		}

		this.devices.forEach( ( device ) => {
			device.vehicle_id = vehicle_id_map[device.hwAddress];
		} );

  	configs.forEach( ( conf ) => {
  		if ( typeof this.configs[conf.device] === typeof undefined )
  		{
  			this.configs[conf.device] = [];
  		}

  		this.configs[conf.device].push( conf.version );
  	} );

  	firmwares.forEach( ( fw ) => {
  		if ( fw.type === 'prod' )
  		{
  			if ( typeof this.firmwaresProd[fw.device] === typeof undefined )
  			{
  				this.firmwaresProd[fw.device] = [];
  			}
  
  			this.firmwaresProd[fw.device].push( convertIdToStringyVersion( fw.version ) );
  		}
  		else
  		{
  			if ( typeof this.firmwaresSafe[fw.device] === typeof undefined )
  			{
  				this.firmwaresSafe[fw.device] = [];
  			}
  
  			this.firmwaresSafe[fw.device].push( convertIdToStringyVersion( fw.version, true ) );
  		}
  	} );

  	this.hot.getInstance( this.id ).updateSettings( this.tableSettings );
  	this.hot.getInstance( this.id ).loadData( this.devices );

  	this.loading = false;
	}

	reviewChanges( )
	{

  	const changes = [];

  	for ( const device in this.changes )
  	{
  		for ( const change in this.changes[device] )
  		{
  			let temp;

  			switch( change )
  			{
  			case "firmware.prod.version":
  			case "firmware.safe.version":

  				temp = { 
  					operation: 'updateOne',
  					query: { hwAddress: parseInt(device) },
  					update: { $set: { } },
  					description: `Change device 0x${parseInt(device).toString(16)} - ${change} from ${convertIdToStringyVersion(this.changes[device][change][0])} to ${convertIdToStringyVersion(this.changes[device][change][1])}`,
  					database: 'dip',
  					collection: 'devices'
  				};

  				temp.update.$set[change] = this.changes[device][change][1];

  				break;

  			case "configuration.version":
  			case "status":
            
  				temp = { 
  					operation: 'updateOne',
  					query: { hwAddress: parseInt(device) },
  					update: { $set: { } },
  					description: `Change device 0x${parseInt(device).toString(16)} - ${change} from ${this.changes[device][change][0]} to ${this.changes[device][change][1]}`,
  					database: 'dip',
  					collection: 'devices'
  				};

  				temp.update.$set[change] = this.changes[device][change][1];

  				break;

  			case 'tdp.properties.owner':
  				temp = { 
  					operation: 'updateOne',
  					query: { hw_address: parseInt(device) },
  					update: { $set: { } },
  					description: `Change device 0x${parseInt(device).toString(16)} - ${change} from ${this.changes[device][change][0]} to ${this.changes[device][change][1]}`,
  					database: 'tdp.prod',
  					collection: 'devices'
  				};

  				temp.update.$set['properties.owner'] = this.changes[device][change][1];

  				break;

  			case 'beta.properties.owner':
  				temp = { 
  					operation: 'updateOne',
  					query: { hw_address: parseInt(device) },
  					update: { $set: { } },
  					description: `Change device 0x${parseInt(device).toString(16)} - ${change} from ${this.changes[device][change][0]} to ${this.changes[device][change][1]}`,
  					database: 'tdp.beta',
  					collection: 'devices'
  				};

  				temp.update.$set['properties.owner'] = this.changes[device][change][1];

  				break;
          
  			}

  			changes.push( temp );
  		}
  	}

  	this.dipService.stageChanges( changes );
  	this.router.navigateByUrl( '/changes' );
	}

	cellEditor( row, col, prop )
	{
  	let data: any;

  	switch ( prop )
  	{
  	case "configuration.version":
  		data = <any> this.hot.getInstance( this.id ).getSourceDataAtRow( row );

  		if ( data && data.type )
  		{
  			return {
  				type: "dropdown",
  				source: this.configs[ data.type ]
  			};
  		}
  		break;

  	case "firmware.prod.version":
  		data = <any> this.hot.getInstance( this.id ).getSourceDataAtRow( row );

  		if ( data && data.type )
  		{
  			return {
  				type: "dropdown",
  				source: this.firmwaresProd[ data.type ],
  				renderer: firmware_renderer
  			};
  		}
  		break;

  	case "firmware.safe.version":
  		data = <any> this.hot.getInstance( this.id ).getSourceDataAtRow( row );

  		if ( data && data.type )
  		{
  			return {
  				type: "dropdown",
  				source: this.firmwaresSafe[ data.type ],
  				renderer: firmware_renderer
  			};
  		}
  		break;
  	}


	}

	async refresh()
	{
  	this.loading = true;

  	try
  	{
  		this.devices = (<Array<any>>( await this.dipService.getDevices( this.include_retired ) )).sort( ( a, b ) => { return a.hwAddress - b.hwAddress; } );
  		console.log( this.devices );
  		this.hot.getInstance( this.id ).loadData( this.devices );
  	}
  	catch( err )
  	{
  		console.error( err );
  	}

  	this.loading = false;
	}

	async createDevices(){

  	console.log( this.new_device_type );
  	console.log( this.device_types.includes( this.new_device_type ) );
  	console.log( this.new_device_count );

  	if ( this.device_types.includes( this.new_device_type ) && this.new_device_count > 0 && this.new_device_count <= 100 )
  	{
  		this.loading = true;

  		try
  		{
  			await this.dipService.createDevices({
  				count: this.new_device_count,
  				model: this.new_device_type
  			});
  
  			this.devices = (<Array<any>>( await this.dipService.getDevices( this.include_retired ) )).sort( ( a, b ) => { return a.hwAddress - b.hwAddress; } );
  			console.log( this.devices );
  			this.hot.getInstance( this.id ).loadData( this.devices );
  		} catch ( err ) {
  			console.error( err );
  		}

  		this.loading = false;
  	}
	}

}
