import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';  
import { DipService } from '../dip.service';
import { HotTableRegisterer } from '@handsontable/angular';
import { Router } from '@angular/router';

@Component({
	selector: 'telemattica-organisation-admin',
	templateUrl: './organisation-admin.component.html',
	styleUrls: ['./organisation-admin.component.css']
})
export class OrganisationAdminComponent implements OnInit {

  private hot = new HotTableRegisterer();

  loading = false;
  new_organisation = "";
  selected_organisation: string | null = null;
  org_name_valid = false;
  organisations: Array<any> = [];
  users: Array<any> = [];
  usersBeforeChanges: Array<any> = [];
  org_user_map = {};
  change_cache = {};
  change_pending = false;

  tableSettings = {
  	data: [],
  	colWidths: 100,
  	width: '100%',
  	height: '100%',
  	rowHeights: 23,
  	rowHeaders: true,
  	colHeaders: true,
  	dropdownMenu: false,
  	filters: false,
  	columnSorting: true,
  	columns: [
  		{
  			data: 'selected',
  			title: " ",
  			width: '30px',
  			type: 'checkbox'
  		},
  		{
  			data: 'name',
  			title: "Name",
  			width: '200px',
  			readOnly: true
  		}
  	]
  }

  usersTableSettings = {
  	data: [],
  	colWidths: 100,
  	width: '100%',
  	height: '100%',
  	rowHeights: 23,
  	rowHeaders: true,
  	colHeaders: true,
  	dropdownMenu: true,
  	filters: true,
  	columnSorting: true,
  	columns: [
  		{
  			data: 'selected',
  			title: " ",
  			width: '30px',
  			type: 'checkbox'
  		},
  		{
  			data: 'email',
  			title: "Name",
  			width: '300px',
  			readOnly: true
  		},
  		{
  			data: 'properties.owner',
  			title: "Owner",
  			width: '150px',
  			readOnly: true
  		}
  	]
  }

	idOrg = 'tableInstance';
  idUsers = 'usersTableInstance';

  constructor( private authService: AuthService,
               private router: Router,
               private dipService: DipService ) { }

  async ngOnInit()
  {
  	await this.authService.authGaurd();

  	this.organisations = await this.dipService.getOrganisations();
  	this.users = await this.dipService.getTdpUsers();

  	this.organisations = this.organisations.map( ( o ) => { o.selected = false; return o; } );
  	this.users = this.users.map( ( u ) => { u.selected = false; return u; } );

  	this.usersBeforeChanges = [ ... this.users.map( ( u ) => { return { ... u }; } ) ]; 

  	this.org_user_map = {};

  	for ( const org of this.organisations )
  	{
  		this.org_user_map[org.name] = {};
  	}

  	this.org_user_map['*'] = {};    // Special character used by the system user

  	for ( const [i, user] of this.users.entries() )
  	{
  		for ( const org of user.access_list )
  		{
  			if ( typeof this.org_user_map[org] === typeof undefined )
  			{
  				this.org_user_map[org] = {};
  				console.warn( `${org} is not in the list of organisations but is in a users access list` );
  			}

  			this.org_user_map[org][user.email] = i;
  		}
  	}

  	this.hot.getInstance( this.idOrg ).loadData( this.organisations );
  	this.hot.getInstance( this.idUsers ).loadData( this.users );

  	this.hot.getInstance( this.idUsers ).addHook( 'afterChange', ( ) => {
      
  		this.change_pending = false;

  		if ( this.selected_organisation === null )
  		{
  			return;
  		}

  		for ( const i in this.change_cache )
  		{
  			if ( this.user_has_changed( i ) )
  			{
  				this.change_pending = true;
  			}
  		}
  	});

  	this.hot.getInstance( this.idUsers ).addHook( 'beforeChange', ( changes ) => {

  		if ( this.selected_organisation === null )
  		{
  			return;
  		}

  		const table = this.hot.getInstance( this.idUsers );

  		this.change_cache[ table.toPhysicalRow( changes[0][0] ) ] = changes[0][3];
  	});

  	this.hot.getInstance( this.idOrg ).addHook( 'beforeChange', ( change ) => {

  		if ( change && change.length == 1 && change[0][1] == 'selected' )
  		{
  			if ( change[0][3] === true )
  			{
  				this.users.forEach( u => u.selected = false );
  				this.selected_organisation = this.organisations[ change[0][0] ].name;
  				this.change_pending = false;

  				for ( const user in this.org_user_map[ this.organisations[ change[0][0] ].name ] )
  				{
  					this.users[this.org_user_map[ this.organisations[ change[0][0] ].name ][user]].selected = true;
  				}
  
  				for ( let i = 0; i < this.organisations.length; i++ )
  				{
  					if ( i !== change[0][0] && this.organisations[i].selected )
  					{
  						this.organisations[i].selected = false;
  					}
  				}
  			}
  			else
  			{
  				this.selected_organisation = null;
  				this.users.forEach( u => u.selected = false );
  				this.change_cache = {};
  				this.change_pending = false;
  			}
  		}

  		this.hot.getInstance( this.idUsers ).render();
  	} );

    
  }

  user_has_changed( user_index )
  {
  	if ( this.users[user_index].selected === false && this.org_user_map[ this.selected_organisation ][ this.users[user_index].email ] )
  	{
  		return true;
  	}
  	else if ( this.users[user_index].selected === true && typeof this.org_user_map[ this.selected_organisation ][ this.users[user_index].email ] === typeof undefined )
  	{
  		return true;
  	}

  	return false;
  }

  review_changes()
  {
  	const changes = [];

  	for ( const i in this.change_cache )
  	{
  		if ( this.user_has_changed( i ) )
  		{
  			console.log( "Diff checked", this.users[i] );
  			if ( this.users[i].selected )
  			{
  				changes.push( { 
  					operation: 'updateOne',
  					query: { email: this.users[i].email },
  					update: { $push: { access_list: this.selected_organisation } },
  					description: `Adding ${this.selected_organisation} to access list for user ${this.users[i].email}`,
  					database: 'tdp.prod',
  					collection: 'admin.users'
  				} );
  			}
  			else
  			{
  				changes.push( { 
  					operation: 'updateOne',
  					query: { email: this.users[i].email },
  					update: { $pull: { access_list: this.selected_organisation } },
  					description: `Removing ${this.selected_organisation} from access list for user ${this.users[i].email}`,
  					database: 'tdp.prod',
  					collection: 'admin.users'
  				} );
  			}
  		}
  	}

  	this.dipService.stageChanges( changes );
  	this.router.navigateByUrl( '/changes' );
  }

  clear_changes()
  {
  	this.users.forEach( u => u.selected = false );
  	this.organisations.forEach( o => o.selected = false );
  	this.change_cache = {};
  	this.change_pending = false;
  	this.hot.getInstance( this.idUsers ).render();
  	this.hot.getInstance( this.idOrg ).render();
  }

  update_new_org_name( name )
  {
  	this.new_organisation = name;

  	const org_names = this.organisations.map( o => o.name );

  	if ( this.new_organisation.length !== 0 && org_names.includes( this.new_organisation ) == false )
  	{
  		this.org_name_valid = true;
  	}
  	else
  	{
  		this.org_name_valid = false;
  	}
  }

  async create_org()
  {
  	this.loading = true;

  	try
  	{
  		await this.dipService.createOrganisation( this.new_organisation ); 
  	}
  	catch( err )
  	{
  		console.error( err );
  	}

  	this.organisations = await this.dipService.getOrganisations();

  	this.hot.getInstance( this.idOrg ).loadData( this.organisations );

  	this.loading = false;
  }
}
