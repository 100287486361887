import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';  
import { DipService } from '../dip.service';

const DEVICE_NOT_CHECKIN_ALERT_TIMEOUT = 24 * 60 * 60 * 1000;

function convertIdToStringyVersion( id, forceSafe?:boolean ){
	let str = "";

	if ( id === 0 || id == null )
	{
		return "UNKNOWN";
	}

	if( 0x80000000 & id || forceSafe ){    // safe
		str = `s_${(id & 0x7F000000) >> 24}.${(id & 0x00FF0000) >> 16}.${(id & 0x0000FFFF)}`;
	} else {        // Prod
		str = `p_${(id & 0x7F000000) >> 24}.${(id & 0x00FF0000) >> 16}.${(id & 0x0000FFFF)}`;
	}

	return str;
}

@Component({
	selector: 'app-alerts',
	templateUrl: './alerts.component.html',
	styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

	report_configs: Array<any> = [];
	report_config: any | null = null;
	installations = [];
	alerts = [];
	selected_report;
	loading_progress = 0;
	loading = false;
	include_yarra_gen2 = false;
	hide_no_checkins = false;

	constructor( private authService: AuthService,
               private dipService: DipService ) 
	{ }

	async ngOnInit() {
		await this.authService.authGaurd();
		this.refresh();
	}

	async refresh() {

		this.loading = true;

  		this.alerts = [];

		try
		{
			let systems = await this.dipService.getVehicles();
			systems = systems.filter( s => s.gateway );

			for ( const system of systems )
			{
				if ( !system.devices ) { continue; }

				for ( const entry of Object.entries( system.devices ) )
				{
					const device:any = entry[ 1 ];
					const hw_address:number = parseInt( entry[ 0 ] );

					this.alerts = this.alerts.concat( this.check_device_for_alerts( device, hw_address, system.vehicle_id, system.gateway, system ) );
				}
			}
		}
		catch( err )
		{
			console.error( err );
		}

		if ( this.alerts.length === 0 )
		{
			this.alerts.push({
				type: "NO_ALERTS",
				title: `No alerts currently active`,
				body: `No alerts currently active`
			});
		}

		console.log( this.alerts );

		this.loading = false;
	}

	check_device_for_alerts( device:any, hw_address:number, vehicle_id:string, gateway:number, system: any ): Array<any>
	{
		const alerts = [];

		const shouldIgnoreNoCheckin = ( ) =>
		{
			let isGen2 = false;

			if ( this.hide_no_checkins ) { return true; }

			for( const deviceAddress in system.devices )
			{
				if ( system.devices[deviceAddress].model === "GW1000" )
				{
					isGen2 = true;
				}
			}

			if ( isGen2 && !this.include_yarra_gen2 && system.properties.owner === "Yarra Trams" )
			{
				return true;
			}
			
			return false;
		}

		if ( ['SU1220_PI', 'SU1210_PI'].includes( device.model ) ||
    		 ( device.settings.status != "OPERATIONAL" ) )
		{
			return [];
		}

		if( !device.device_health )
		{
			alerts.push({
				type: "DEVICE_MISSING_HEALTH_DATA",
				title: `${vehicle_id} (0x${gateway.toString(16)}) - ${device.model} 0x${hw_address.toString(16)} is missing health data`,
				body: JSON.stringify( device, null, 2 )
			});
		}
		else if( !device.settings.firmware || !device.settings.configuration )
		{
			alerts.push({
				type: "DEVICE_MISSING_SETTINGS_DATA",
				title: `${vehicle_id} (0x${gateway.toString(16)}) - ${device.model} 0x${hw_address.toString(16)} is missing settings data`,
				body: "Settings object: " + JSON.stringify( device.settings, null, 2 )
			});
		}
		else 
		{
			if ( device.device_health.firmware.current > 0x7FFFFFFF )
			{
				alerts.push({
					type: "DEVICE_IN_SAFE_MODE",
					title: `${vehicle_id} (0x${gateway.toString(16)}) - ${device.model} 0x${hw_address.toString(16)} is in safe mode`
				});
			}
			else if( device.device_health.firmware.current != device.settings.firmware.prod.version )
			{
				alerts.push({
					type: "DEVICE_SETTINGS_MISMATCH_FIRMWARE",
					title: `${vehicle_id} (0x${gateway.toString(16)}) - ${device.model} 0x${hw_address.toString(16)} has different firmware than configured`,
					body: `0x${hw_address.toString(16)} is set to ${convertIdToStringyVersion(device.settings.firmware.prod.version)} but is running ${convertIdToStringyVersion(device.device_health.firmware.current)}`
				});
			}

			if( device.model !== 'PM1010' && device.device_health.config != device.settings.configuration.version )
			{
				// Check if a command is pending for this device
				alerts.push({
					type: "DEVICE_SETTINGS_MISMATCH_CONFIG",
					title: `${vehicle_id} (0x${gateway.toString(16)}) - ${device.model} 0x${hw_address.toString(16)} has different config than configured`,
					body: `${device.model} 0x${hw_address.toString(16)} config is set to ${device.settings.configuration.version} but is running ${device.device_health.config}`
				});
			}

			const last_checkin = new Date( device.device_health.last_updated );
			if( last_checkin.getTime() < ( Date.now() - DEVICE_NOT_CHECKIN_ALERT_TIMEOUT ) )
			{
				if ( shouldIgnoreNoCheckin() === false )
				{
					alerts.push({
						type: "DEVICE_NO_CHECKIN_24HOURS",
						title: `${vehicle_id} (0x${gateway.toString(16)}) - ${device.model} 0x${hw_address.toString(16)} has not checked in for more than 24 hours last checked in ${last_checkin.toLocaleString()}`,
						body: `${device.model} 0x${hw_address.toString(16)} last checked in ${last_checkin.toLocaleString()}`
					});
				}
			}
		}

		return alerts;
	}

}
