import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'telemattica-issues',
	templateUrl: './issues.component.html',
	styleUrls: ['./issues.component.css']
})
export class IssuesComponent implements OnInit {

	log = console.log;

	constructor() { }

	ngOnInit(): void {
	}

  

}
