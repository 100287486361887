import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class DipService {

	stagedChanges = [];

	constructor( private authService: AuthService,
               private http: HttpClient,
               private config: ConfigService ) 
	{
  	const cache = window.localStorage.getItem( 'staged_changes' );

  	if ( typeof cache === typeof 'string' )
  	{
  		try
  		{
  			this.stagedChanges = JSON.parse( cache );
  		}
  		catch( err )
  		{
  			console.log( err );
  			this.stagedChanges = [];
  		}
  	}
	}

	executeChanges( changes )
	{
  	return new Promise( ( resolve, reject ) => {
  		this.http.post( this.config.dipUrl + "/app/changes", changes, {
  			headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
  		} )
  			.subscribe( resolve, reject );
  	} );
	}

	stageChanges( changes )
	{
  	this.stagedChanges = changes;
  	window.localStorage.setItem( 'staged_changes', JSON.stringify( this.stagedChanges ) );
	}

	getStagedChanges( ): any[]
	{
  		return this.stagedChanges;
	}

	getAlertViewStats( start:string, end:string ): Promise<any>
	{
		const query_str = `?start=${start}&end=${end}`;

		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/reports/alert_views${query_str}`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getDevices( include_retired )
	{
		let query_str = "";
		
		if( include_retired )
		{
			query_str = `?include_retired=${include_retired}`;
		}

		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/devices/all${query_str}`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	private getGW1000s(): Promise<Array<any>>
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + "/app/devices/type/GW1000", {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	private getGW2000s(): Promise<Array<any>>
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + "/app/devices/type/GW2000", {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	async getGateways() : Promise<Array<any>>
	{
		let gateways = [];
		gateways = gateways.concat( await this.getGW1000s() );
		gateways = gateways.concat( await this.getGW2000s() );

		return gateways;
	}

	getReportConfigs() : Promise<Array<any>>
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + "/app/reports/", {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getSystemLogs( hwAddress, start, end ) : Promise<Array<any>>
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/logs/gateway/${hwAddress}?start=${start.toISOString()}&end=${end.toISOString()}`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getOverviewReportData( start, end ) : Promise<any>
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/reports/overview?start=${start.toISOString()}&end=${end.toISOString()}`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getPowerHealths( hwAddress, start, end ) : Promise<Array<any>>
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/systems/${hwAddress}/power?start=${start.toISOString()}&end=${end.toISOString()}`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getGW2000PowerHealths( hwAddress, start, end ) : Promise<Array<any>>
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/systems/${hwAddress}/gw2000power?start=${start.toISOString()}&end=${end.toISOString()}`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getLatestDeviceHealth( hwAddress )
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/devices/${hwAddress}`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getLatestCommands( gateway ) : Promise<any[]>
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/commands/${gateway}`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	createDevices( params ) : Promise<any>
	{
		return new Promise( ( resolve, reject ) => {
			this.http.post( this.config.dipUrl + `/app/devices/create`, params, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getVehicles( ): Promise<Array<any>>
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/systems/`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getConfigs( ): Promise<Array<any>>
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/configs/`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getFirmwares( )
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/fws/all`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getOrganisations( ) : Promise<Array<any>>
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/organisations/`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getTdpUsers( ) : Promise<Array<any>>
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/users/tdp/`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	createOrganisation( name )
	{
		return new Promise( ( resolve, reject ) => {
			this.http.post( this.config.dipUrl + `/app/organisations/`, { name: name }, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getLatestSystemHealth( hwAddress )
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/systems/${hwAddress}/health`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}

	getLocationPowerData( lat:number, lng:number )
	{
		return new Promise( ( resolve, reject ) => {
			this.http.get( this.config.dipUrl + `/app/reports/location-power-report?latitude=${lat}&longitude=${lng}`, {
				headers: new HttpHeaders({'Authorization' : 'Bearer ' + this.authService.accessToken })
			} )
				.subscribe( resolve, reject );
		} );
	}
}
