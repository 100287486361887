<div class="grid">
    <div class="grid-main-view">
        <p><strong>{{error_message}}</strong></p>
        <ul>
            <li *ngFor="let change of changes; let i = index"> (1)
                {{ change.description }} <button (click)="removeChangeItem(i)" >UN-STAGE</button>
            </li>
        </ul>
    </div>

    <div class="grid-side-bar">
        <button mat-raised-button color="primary" (click)="executeChanges()">EXECUTE CHANGES</button><br><br>
        <button mat-raised-button color="primary" (click)="rejectChanges()">REJECT CHANGES</button><br><br>
    </div>
</div>
<div *ngIf="loading" class="loading-overlay" >
    <mat-spinner></mat-spinner>
</div>