import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlertsComponent } from './alerts/alerts.component';
import { LoginComponent } from './login/login.component';
import { OverviewComponent } from './overview/overview.component';
import { LogsComponent } from './logs/logs.component';
import { DevicesComponent } from './devices/devices.component';
import { PowerComponent } from './power/power.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { CommandsComponent } from './commands/commands.component';
import { ChangeManagerComponent } from './change-manager/change-manager.component';
import { UserReportComponent } from './user-report/user-report.component';
import { LocationPowerReportComponent } from './location-power-report/location-power-report.component';
import { ConfigManagerComponent } from './config-manager/config-manager.component';
import { OrganisationAdminComponent } from './organisation-admin/organisation-admin.component';
import { IssuesComponent } from './issues/issues.component';

const routes: Routes = [
	{ path: '', redirectTo: '/login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent },
	{ path: 'overview', component: OverviewComponent },
	{ path: 'alerts', component: AlertsComponent },
	{ path: 'logs', component: LogsComponent },
	{ path: 'devices', component: DevicesComponent },
	{ path: 'power', component: PowerComponent },
	{ path: 'vehicles', component: VehiclesComponent },
	{ path: 'commands', component: CommandsComponent },
	{ path: 'changes', component: ChangeManagerComponent },
	{ path: 'user-report', component: UserReportComponent },
	{ path: 'location-power-report', component: LocationPowerReportComponent },
	{ path: 'config-manager', component: ConfigManagerComponent },
	{ path: 'organisation-admin', component: OrganisationAdminComponent },
	{ path: 'issues', component: IssuesComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
