<div class="grid">
    <div class="grid-main-view">
        <div *ngIf="showView" style="overflow: auto; height: 100%; width: 100%;">
            <hot-table 
                [hotId]="id"
                [settings]="tableSettings"
                licenseKey="non-commercial-and-evaluation"></hot-table>
        </div>

        <div *ngIf="showDetails" style="overflow: auto; height: 100%; width: 100%;">
            <button (click)="showTable()">BACK</button>
            <div [innerHTML]="detailedConfigData"></div>
        </div>

    </div>

    <div class="grid-side-bar">
        <button mat-raised-button color="primary" (click)="reviewChanges()">REVIEW CHANGES</button>
    </div>
</div>

<div *ngIf="loading" class="loading-overlay" >
    <mat-spinner></mat-spinner>
</div>