<div class="grid">
    <div class="grid-main-view">
        <div class="main-grid">
            <div class="main-left-panel">
                <hot-table 
                    [hotId]="idOrg"
                    [settings]="tableSettings"
                    licenseKey="non-commercial-and-evaluation"></hot-table>
            </div>
            <div class="main-right-panel">
                <hot-table 
                    [hotId]="idUsers"
                    [settings]="usersTableSettings"
                    licenseKey="non-commercial-and-evaluation"></hot-table>
            </div>
        </div>
    </div>

    <div class="grid-side-bar">
        New Org Name: <input #newOrg type="text" (keyup)="update_new_org_name(newOrg.value)"><br><br>
        <button [disabled]="!org_name_valid" mat-raised-button color="primary" (click)="create_org()">CREATE</button>
        <hr>
        <button [disabled]="!change_pending" mat-raised-button color="primary" (click)="clear_changes()">CLEAR CHANGES</button><br><br>
        <button [disabled]="!change_pending" mat-raised-button color="primary" (click)="review_changes()">REVIEW CHANGES</button>
    </div>
</div>

<div *ngIf="loading" class="loading-overlay" >
    <mat-spinner></mat-spinner>
</div>