<div class="grid">
    <div class="grid-main-view">
        <hot-table 
            [hotId]="id"
            [settings]="tableSettings"
            licenseKey="non-commercial-and-evaluation"></hot-table>
    </div>

    <div class="grid-side-bar">
        Include Retired HW: <input type="checkbox" [(ngModel)]="include_retired"><br>
        <button mat-raised-button color="primary" (click)="refresh()">REFRESH</button><br>

        <h4>Create Devices</h4>
        <select id="device_type" [(ngModel)]="new_device_type"></select><br>
        Number of new Devices: <input type="number" id="device_type" [(ngModel)]="new_device_count" ><br>
        <button mat-raised-button color="primary" (click)="createDevices()">CREATE</button><br>

        <hr>

        <button mat-raised-button color="primary" (click)="reviewChanges()">REVIEW CHANGES</button>
    </div>
</div>
<div *ngIf="loading" class="loading-overlay" >
    <mat-spinner></mat-spinner>
</div>