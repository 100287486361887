import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { OverviewComponent } from './overview/overview.component';
import { AlertsComponent } from './alerts/alerts.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AgmCoreModule } from '@agm/core';

import { HotTableModule } from '@handsontable/angular';

import { GoogleChartsModule } from 'angular-google-charts';
import { LogsComponent } from './logs/logs.component';
import { DevicesComponent } from './devices/devices.component';
import { PowerComponent } from './power/power.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { CommandsComponent } from './commands/commands.component';
import { ChangeManagerComponent } from './change-manager/change-manager.component';
import { UserReportComponent } from './user-report/user-report.component';
import { LocationPowerReportComponent } from './location-power-report/location-power-report.component';
import { ConfigManagerComponent } from './config-manager/config-manager.component';
import { OrganisationAdminComponent } from './organisation-admin/organisation-admin.component';
import { IssuesComponent } from './issues/issues.component';



// @ts-ignore
Date.prototype.addHours = function( h ) 
{
	this.setTime( this.getTime() + ( h * 60 * 60 * 1000 ));
	return this;
};

// @ts-ignore
Date.prototype.adjustLocal = function() 
{
	this.setTime( this.getTime() - (( this.getTimezoneOffset() / 60 ) * 60 * 60 * 1000 ) );
	return this;
};

// @ts-ignore
Date.prototype.toDateTimeLocalString = function()
{
	return this.toISOString().slice( 0, 16 );
};

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		OverviewComponent,
		AlertsComponent,
		LogsComponent,
		DevicesComponent,
		PowerComponent,
		VehiclesComponent,
		CommandsComponent,
		ChangeManagerComponent,
		UserReportComponent,
		LocationPowerReportComponent,
  		ConfigManagerComponent,
    	OrganisationAdminComponent,
		IssuesComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		MatCardModule,
		MatSliderModule,
		MatButtonModule,
		HotTableModule,
		GoogleChartsModule,
		MatMenuModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatExpansionModule,
		MatDialogModule,
		DragDropModule,
		MatTabsModule,
		MatSnackBarModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyD3f4yATA_81J_ZArELTmR6CHEFEY_asck'
		}),
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
