<div class="grid">
    <div class="grid-main-view">
            <hot-table 
                [hotId]="id"
                [settings]="tableSettings"
                licenseKey="non-commercial-and-evaluation"></hot-table>
    </div>

    <div class="grid-side-bar">
        <button mat-raised-button color="primary" (click)="refresh()">REFRESH</button>
    </div>
</div>

<div *ngIf="loading" class="loading-overlay" >
    <mat-spinner></mat-spinner>
</div>