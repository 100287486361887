// @ts-nocheck

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';  
import { DipService } from '../dip.service';
import { HotTableRegisterer } from '@handsontable/angular';

import * as moment from 'moment';
import Handsontable from 'handsontable';

function convertIdToStringyVersion(id){
	let str = "";

	if ( id === 0 || id == null )
	{
		return "UNKNOWN";
	}

	if(0x80000000 & id){    // safe
		str = `s_${(id & 0x7F000000) >> 24}.${(id & 0x00FF0000) >> 16}.${(id & 0x0000FFFF)}`;
	} else {        // Prod
		str = `p_${(id & 0x7F000000) >> 24}.${(id & 0x00FF0000) >> 16}.${(id & 0x0000FFFF)}`;
	}

	return str;
}

@Component({
	selector: 'app-vehicles',
	templateUrl: './vehicles.component.html',
	styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit {

	private hot = new HotTableRegisterer();

	id = 'tableInstance';

	tableSettings = {
  	data: [],
  	colWidths: 100,
  	width: '100%',
  	height: '100%',
  	rowHeights: 23,
  	rowHeaders: true,
  	colHeaders: true,
  	dropdownMenu: true,
  	filters: true,
  	columnSorting: true,
  	columns: [
  		{
  			data: 'vehicle_id',
  			title: "Vehicle ID",
  			width: '150px',
  			readOnly: true
  		},
  		{
  			data: 'hw_address',
  			title: "Address",
  			width: '100px',
  			readOnly: true
  		},
  		{
  			data: 'type',
  			title: "Type",
  			width: '100px',
  			readOnly: true
  		},
  		{
  			data: 'owner',
  			title: "Owner",
  			width: '150px',
  			readOnly: true
  		},
  		{
  			data: 'current_fw',
  			title: "Curr FW",
  			width: '100px',
  			renderer: function(instance: any, td: any, row: any, col: any, prop: any, value: any, cellProperties: any) {

  				let img = null;

  				if ( value && value.indexOf('s_') >= 0 ) {
  					img = document.createElement('strong');
  					img.innerHTML = value;
  					// @ts-ignore
  					img.style = "color: red;";

  					Handsontable.dom.empty(td);
  					td.appendChild(img);

  				} else {
  					// @ts-ignore
  					Handsontable.renderers.TextRenderer.apply(this, arguments);
  				}

  				return td;
  			},
  			readOnly: true
  		},
  		{
  			data: 'prod_fw',
  			title: "Prod FW",
  			width: '100px',
  			readOnly: true
  		},
  		{
  			data: 'safe_fw',
  			title: "Safe FW",
  			width: '100px',
  			readOnly: true
  		},
  		{
  			data: 'config',
  			title: "Config",
  			width: '100px',
  			readOnly: true
  		},
  		{
  			data: 'timestamp',
  			title: "Last Seen",
  			width: '150px',
  			renderer: function(instance, td, row, col, prop, value, cellProperties) {

  				const p = document.createElement('p');
  				p.innerHTML = moment( value ).fromNow();

  				Handsontable.dom.empty(td);
  				td.appendChild(p);

  				return td;
  			},
  			readOnly: true
  		}
  	]
	}

	rowData = [];

	vehicles: any;

	loading = false;

	constructor( private authService: AuthService,
               private dipService: DipService ) { }

	async ngOnInit() {
  	await this.authService.authGaurd();

  	Handsontable.hooks.add( 'afterFilter', console.log, this.hot.getInstance( this.id ) );

  	this.refresh();
	}

	async refresh()
	{
	  this.loading = true;
  	this.vehicles = ( <Array<any>>( await this.dipService.getVehicles() ) ).filter( ( a ) => { if ( a.gateway ) { return true; } return false; } );

  	this.vehicles = this.vehicles.sort( ( a, b ) => {
  		return a.properties.owner.localeCompare( b.properties.owner ) || a.vehicle_id.localeCompare( b.vehicle_id );
  	});

  	const devices = [];

  	for ( const i in this.vehicles )
  	{
  		for ( const device in this.vehicles[i].devices )
  		{
  			const dev: any = {
  				owner: this.vehicles[i].properties.owner,
  				vehicle_id: this.vehicles[i].vehicle_id,
  				hw_address: parseInt( device ).toString( 16 ).toUpperCase(),
  				type: this.vehicles[i].devices[device].model
  			};

  			if ( this.vehicles[i].devices[device].device_health && this.vehicles[i].devices[device].device_health.firmware )
  			{
  				dev.current_fw = convertIdToStringyVersion( this.vehicles[i].devices[device].device_health.firmware.current );
  				dev.prod_fw = convertIdToStringyVersion( this.vehicles[i].devices[device].device_health.firmware.prod );
  				dev.safe_fw = convertIdToStringyVersion( this.vehicles[i].devices[device].device_health.firmware.safe );
  				dev.timestamp = this.vehicles[i].devices[device].device_health.last_updated;
  				dev.config = this.vehicles[i].devices[device].device_health.config;
  			}

  			devices.push( dev );
  		}
  	}

  	console.log( this.vehicles );

  	this.hot.getInstance( this.id ).loadData( devices );
	  this.loading = false;
	}

}
