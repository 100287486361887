import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';  
import { DipService } from '../dip.service';
import { HotTableRegisterer } from '@handsontable/angular';

import * as moment from 'moment';
import Handsontable from 'handsontable';

@Component({
	selector: 'app-commands',
	templateUrl: './commands.component.html',
	styleUrls: ['./commands.component.css']
})
export class CommandsComponent implements OnInit {

	loading = false;

	private hot = new HotTableRegisterer();

	commands: any[] = [];

	tableSettings = {
  	data: [],
  	colWidths: 100,
  	width: '100%',
  	height: '100%',
  	rowHeights: 23,
  	rowHeaders: true,
  	colHeaders: true,
  	dropdownMenu: true,
  	filters: true,
  	columnSorting: true,
  	contextMenu: {
  		callback: ( key, selection, clickEvent ) => {
  			// Common callback for all options
  			// console.log( key, selection, clickEvent );

  			switch( key )
  			{
  			case "abort":
  				console.log( selection );

  				for ( const sel of selection )
  				{
  					for ( let i = sel.start.row; i <= sel.end.row; i++ )
  					{
  						console.log( this.hot.getInstance( this.id ).getSourceDataAtRow( this.hot.getInstance( this.id ).toPhysicalRow( i ) ) );
  					}
  				}
            
  				break;

  			default:
  				console.log( "Unknown contextual command" );
  				break;
  			}



  		},
  		items: {
  			"abort": {
  				name: "Abort command(s)"
  			}
  		}
  	},
  	columns: [
  		{
  			data: 'id',
  			title: "ID",
  			width: '100px'
  		},
  		{
  			data: 'group',
  			title: "Group",
  			width: '100px'
  		},
  		{
  			data: 'status',
  			title: "Status",
  			width: '100px'
  		},
  		{
  			data: 'command',
  			title: "Command",
  			width: '100px'
  		},
  		{
  			data: 'args',
  			title: "Args",
  			width: '100px'
  		},
  		{
  			data: 'target.hwAddress',
  			title: "Target",
  			width: '100px'
  		},
  		{
  			data: 'response.raw',
  			title: "Response",
  			width: '600px'
  		},
  		{
  			data: 'createdAt',
  			title: "CreatedAt",
  			width: '250px'
  		},
  		{
  			data: 'sentAt',
  			title: "SentAt",
  			width: '250px'
  		},
  		{
  			data: 'responseAt',
  			title: "ResponseAt",
  			width: '250px'
  		},
  		{
  			data: 'abortAt',
  			title: "AbortAt",
  			width: '250px'
  		},
  		{
  			data: '_id',
  			title: "DB ID",
  			width: '250px'
  		},
  	]
	}

	id = 'tableInstance';

	constructor( private authService: AuthService,
               private dipService: DipService ) { }

	async ngOnInit() {
  	await this.authService.authGaurd();

  	const gateways = await this.dipService.getGateways();

  	const x = document.getElementById("hwAddress");
  	// @ts-ignore
  	x.options.length = 0;
  	gateways.forEach((gateway) => {
  		const option = document.createElement("option");
  		option.text = gateway.hwAddress.toString(16).toUpperCase();
  		option.value = gateway.hwAddress;
  		// @ts-ignore
  		x.add(option);
  	});
	}

	async refresh() {
  	this.loading = true;

  	try 
  	{
  		const hwAddress = (<HTMLInputElement>document.getElementById('hwAddress')).value;
  		this.commands = await this.dipService.getLatestCommands( hwAddress );
  		this.hot.getInstance( this.id ).loadData( this.commands );

  	} 
  	catch ( err ) 
  	{
  		console.error( err );
  	}
    
  	this.loading = false;
	}



}
