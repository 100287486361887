<div class="grid">
    <div class="grid-main-view">
        <div class="main-grid">
            <div class="main-panel main-panel-1-1" >
                <h2>Power (%)</h2>
                <div id="chart-power"></div>
            </div>
            <div class="main-panel main-panel-1-2" >
                <h2>Events</h2>
                <div id="chart-events"></div>
            </div>
            <div class="main-panel main-panel-2-1" >
                <h2>Distance Travelled (km)</h2>
                <div id="chart-distance"></div>
            </div>
            <div class="main-panel main-panel-2-2" >
                <h2>Data Filter</h2>
            </div>
        </div>
    </div>

    <div class="grid-side-bar">
        Organisation: <select (change)="update_plots()" id="organisation" [(ngModel)]="organisation">
            <option *ngFor="let org of organisations" [value]="org">{{org}}</option>
        </select><br><br>
        Start: <input id="startDate" type="datetime-local" [(ngModel)]="startDate"><br>
        End: <input id="endDate" type="datetime-local" [(ngModel)]="endDate"><br><br>
        <button mat-raised-button color="primary" (click)="refresh()">REFRESH</button>
    </div>
</div>

<div *ngIf="loading" class="loading-overlay" >
    <mat-spinner mode="indeterminate" ></mat-spinner>
</div>
