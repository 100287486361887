// @ts-nocheck

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { DipService } from '../dip.service';
import * as c3 from 'c3';

const default_fields_in_stats = [ 'others', 'total', 'time_to_view', 'view_hist' ];
const milli_to_days_const = 1000 * 60 * 60 * 24;

function mean( nums )
{
	if ( nums.length === 0 )
	{
		return null;
	}

	return nums.reduce( ( a, b ) => ( a + b ) ) / nums.length;
}

@Component({
	selector: 'app-user-report',
	templateUrl: './user-report.component.html',
	styleUrls: ['./user-report.component.css']
})
export class UserReportComponent implements OnInit {

	loading = false;
	orgs: any = [];
	stats: any | null = null;
	org_total = 0;

	constructor( private authService: AuthService,
               private dipService: DipService ) 
	{ }

	async ngOnInit()
	{
  	this.loading = true;

  	try
  	{
  		await this.authService.authGaurd();

  		const start = <HTMLInputElement>document.getElementById( "startDate" );
  		const end = <HTMLInputElement>document.getElementById( "endDate" );

  		// @ts-ignore
  		start.value = (new Date()).adjustLocal().addHours( -24 * 31 ).toDateTimeLocalString();
  		// @ts-ignore
  		end.value = (new Date()).adjustLocal().toDateTimeLocalString();

  		this.orgs = await this.dipService.getOrganisations();

  		await this.refresh();
  	}
  	catch( err )
  	{
  		console.error( err );
  	}

  	this.loading = false;
	}

	async refresh()
	{
  	this.loading = true;

  	const startDate = new Date((<HTMLInputElement>document.getElementById("startDate")).value);
  	const endDate = new Date((<HTMLInputElement>document.getElementById("endDate")).value);
    
  	try
  	{
  		this.stats = await this.dipService.getAlertViewStats( startDate.toISOString(), endDate.toISOString() );
  	}
  	catch( err )
  	{
  		console.error( err );
  	}

  	// @ts-ignore
  	c3.generate( this.generate_alert_views_stats() );
  	// @ts-ignore
  	c3.generate( this.generate_per_user_chart( this.orgs[0] ) );
  	// @ts-ignore
  	c3.generate( this.generate_alert_view_hist( "All" ) );
  	// @ts-ignore
  	c3.generate( this.generate_mean_time_to_view() );

  	this.loading = false;
	}

	changed_org_plot( org )
	{
  	// @ts-ignore
  	c3.generate( this.generate_per_user_chart( org ) );
	}

	changed_hist_org( org )
	{
  	// @ts-ignore
  	c3.generate( this.generate_alert_view_hist( org ) );
	}

	generate_alert_views_stats()
	{
  	const alert_percentage = {
  		bindto: "#percentChart",
  		data: {
  			columns: [
  				[ 'Telemattica' ],
  				[ 'Internal' ]
  			],
  			type: 'bar'
  		},
  		axis: {
  			x: {
  				type: 'category',
  				categories: [  ]
  			},
  			y: {
  				min: 0,
  				max: 100,
  				padding: { top: 0, bottom: 0 }
  			}
  		}
  	};

  	if ( this.stats )
  	{
  		for ( const org in this.stats )
  		{
  			if ( this.stats[org].total === 0 )
  			{
  				continue;
  			}

  			alert_percentage.axis.x.categories.push( org );

  			let tele = false;
  			let other = false;

  			for ( const view_org in this.stats[org] )
  			{
  				if ( view_org === 'Telemattica' )
  				{
  					// @ts-ignore
  					alert_percentage.data.columns[0].push( Math.round( 100 * this.stats[org][view_org].total / this.stats[org].total ) );
  					tele = true;
  				}
  				else if ( view_org === 'other' )
  				{
  					// @ts-ignore
  					alert_percentage.data.columns[1].push( Math.round( 100 * this.stats[org][view_org].total / this.stats[org].total ) );
  					other = true;
  				}
  			}

  			if ( tele == false )
  			{
  				// @ts-ignore
  				alert_percentage.data.columns[0].push( 0 );
  			}

  			if ( other == false )
  			{
  				// @ts-ignore
  				alert_percentage.data.columns[1].push( 0 );
  			}
  		}
  	}

  	return alert_percentage;
	}

	generate_per_user_chart( org_filter )
	{
  	const hist = {
  		bindto: "#userChart",
  		data: {
  			columns: [
  				[ 'User' ]
  			],
  			type: 'bar',
  			units: "%"
  		},
  		axis: {
  			x: {
  				type: 'category',
  				categories: [  ]
  			},
  			y: {
  				min: 0,
  				padding: { top: 0, bottom: 0 },
  				label: "Percentage",
            
  			}
  		}
  	};

  	const temp_stats = {};

  	if ( this.stats && this.stats[ org_filter ] )
  	{
  		for ( const view_org in this.stats[ org_filter ] )
  		{
  			if ( default_fields_in_stats.includes( view_org ) )
  			{
  				continue;
  			}

  			for ( const user in this.stats[ org_filter ][ view_org ] )
  			{
  				if ( user !== "total" )
  				{
  					temp_stats[ user ] = Math.round( 100 * this.stats[ org_filter ][ view_org ][ user ] / this.stats[ org_filter ].total );
  				}
  			}
  		}

  		this.org_total = this.stats[ org_filter ].total;
  	}

  	for ( const user in temp_stats )
  	{
  		hist.axis.x.categories.push( user );
  		hist.data.columns[0].push( temp_stats[ user ] );
  	}

  	return hist;
	}

	generate_mean_time_to_view( )
	{
  	const mean_times = {
  		bindto: "#timeChart",
  		data: {
  			columns: [
  				[ 'Telemattica' ],
  				[ 'Internal' ]
  			],
  			type: 'bar'
  		},
  		axis: {
  			x: {
  				type: 'category',
  				categories: [  ]
  			},
  			y: {
  				min: 0,
  				padding: { top: 0, bottom: 0 }
  			}
  		}
  	};

  	if ( this.stats )
  	{
  		for ( const org in this.stats )
  		{
  			if ( this.stats[org].total === 0 )
  			{
  				continue;
  			}

  			mean_times.axis.x.categories.push( org );

  			let others = [];

  			for ( const view_org in this.stats[org].time_to_view )
  			{
  				if ( view_org === 'Telemattica' )
  				{
  					// @ts-ignore
  					mean_times.data.columns[0].push( mean( this.stats[org].time_to_view[view_org] ) / milli_to_days_const );
  				}
  				else
  				{
  					others = others.concat( this.stats[org].time_to_view[view_org] );
  				}
  			}

  			// @ts-ignore
  			mean_times.data.columns[1].push( mean( others ) / milli_to_days_const );
  		}
  	}

  	return mean_times;
	}

	generate_alert_view_hist( org_filter )
	{
  	const hist = {
  		bindto: "#histChart",
  		data: {
  			columns: [
  				[ 'Telemattica' ],
  				[ 'Internal' ]
  			],
  			type: 'bar'
  		},
  		axis: {
  			x: {
  				type: 'category',
  				categories: [ "0", "1", "2", "3", "4", "5+" ]
  			},
  			y: {
  				min: 0,
  				padding: { top: 0, bottom: 0 }
  			}
  		}
  	};

  	const hist_tele = {
  		"0": 0,
  		"1": 0,
  		"2": 0,
  		"3": 0,
  		"4": 0,
  		"5+": 0,
  		"total": 0
  	};

  	const hist_other = {
  		"0": 0,
  		"1": 0,
  		"2": 0,
  		"3": 0,
  		"4": 0,
  		"5+": 0,
  		"total": 0
  	};

  	if ( this.stats )
  	{
  		for ( const org in this.stats )
  		{
  			if ( ( this.stats[org].total === 0 ) ||
             ( org_filter !== "All" && org_filter !== org ) )
  			{
  				continue;
  			}

  			for ( const view_org in this.stats[org].view_hist )
  			{
  				if ( default_fields_in_stats.includes( view_org ) )
  				{
  					continue;
  				}

  				if ( view_org === "Telemattica" )
  				{
  					hist_tele["0"] += this.stats[org].view_hist[view_org]["0"];
  					hist_tele["1"] += this.stats[org].view_hist[view_org]["1"];
  					hist_tele["2"] += this.stats[org].view_hist[view_org]["2"];
  					hist_tele["3"] += this.stats[org].view_hist[view_org]["3"];
  					hist_tele["4"] += this.stats[org].view_hist[view_org]["4"];
  					hist_tele["5+"] += this.stats[org].view_hist[view_org]["5+"];
  					hist_tele["total"] += this.stats[org].view_hist[view_org]["0"] + 
                                  this.stats[org].view_hist[view_org]["1"] +
                                  this.stats[org].view_hist[view_org]["2"] +
                                  this.stats[org].view_hist[view_org]["3"] +
                                  this.stats[org].view_hist[view_org]["4"] +
                                  this.stats[org].view_hist[view_org]["5+"];
  				}
  				else
  				{
  					hist_other["0"] += this.stats[org].view_hist[view_org]["0"];
  					hist_other["1"] += this.stats[org].view_hist[view_org]["1"];
  					hist_other["2"] += this.stats[org].view_hist[view_org]["2"];
  					hist_other["3"] += this.stats[org].view_hist[view_org]["3"];
  					hist_other["4"] += this.stats[org].view_hist[view_org]["4"];
  					hist_other["5+"] += this.stats[org].view_hist[view_org]["5+"];
  					hist_other["total"] += this.stats[org].view_hist[view_org]["0"] + 
                                  this.stats[org].view_hist[view_org]["1"] +
                                  this.stats[org].view_hist[view_org]["2"] +
                                  this.stats[org].view_hist[view_org]["3"] +
                                  this.stats[org].view_hist[view_org]["4"] +
                                  this.stats[org].view_hist[view_org]["5+"];
  				}
  			}
  		}
  	}

  	// @ts-ignore
  	hist.data.columns[0].push( Math.round( 100 * hist_tele["0"] / hist_tele["total"] ) );
  	// @ts-ignore
  	hist.data.columns[0].push( Math.round( 100 * hist_tele["1"] / hist_tele["total"] ) );
  	// @ts-ignore
  	hist.data.columns[0].push( Math.round( 100 * hist_tele["2"] / hist_tele["total"] ) );
  	// @ts-ignore
  	hist.data.columns[0].push( Math.round( 100 * hist_tele["3"] / hist_tele["total"] ) );
  	// @ts-ignore
  	hist.data.columns[0].push( Math.round( 100 * hist_tele["4"] / hist_tele["total"] ) );
  	// @ts-ignore
  	hist.data.columns[0].push( Math.round( 100 * hist_tele["5+"] / hist_tele["total"] ) );

  	// @ts-ignore
  	hist.data.columns[1].push( Math.round( 100 * hist_other["0"] / hist_other["total"] ) );
  	// @ts-ignore
  	hist.data.columns[1].push( Math.round( 100 * hist_other["1"] / hist_other["total"] ) );
  	// @ts-ignore
  	hist.data.columns[1].push( Math.round( 100 * hist_other["2"] / hist_other["total"] ) );
  	// @ts-ignore
  	hist.data.columns[1].push( Math.round( 100 * hist_other["3"] / hist_other["total"] ) );
  	// @ts-ignore
  	hist.data.columns[1].push( Math.round( 100 * hist_other["4"] / hist_other["total"] ) );
  	// @ts-ignore
  	hist.data.columns[1].push( Math.round( 100 * hist_other["5+"] / hist_other["total"] ) );

  	return hist;
	}
}
