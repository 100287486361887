<div style="width:50%">
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="log($event)">
        <mat-tab label="Unresolved"></mat-tab>
        <mat-tab label="Pending"></mat-tab>
        <mat-tab label="Ignored"></mat-tab>
        <mat-tab label="Resolved"></mat-tab>
        <mat-tab label="All"></mat-tab>
    </mat-tab-group>
</div>




