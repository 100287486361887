import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';  
import { DipService } from '../dip.service';
import * as c3 from 'c3';

const ACC_UNITS_TO_WH_4mOHM = 85000;

@Component({
	selector: 'app-power',
	templateUrl: './power.component.html',
	styleUrls: ['./power.component.css']
})
export class PowerComponent implements OnInit {

	startDate: string;
	endDate: string;
	loading = false;
	isGen3 = false;
	gateways: Array<any> = [];

	constructor( private authService: AuthService,
               private dipService: DipService ) { }

	async ngOnInit() {
  	await this.authService.authGaurd();

  	const start = <HTMLInputElement>document.getElementById("startDate");
  	const end = <HTMLInputElement>document.getElementById("endDate");

  	// @ts-ignore
  	start.value = (new Date()).adjustLocal().addHours(-24).toDateTimeLocalString();
  	// @ts-ignore
  	end.value = (new Date()).adjustLocal().toDateTimeLocalString();

  	this.gateways = await this.dipService.getGateways();

  	const x = document.getElementById("hwAddress");
  	// @ts-ignore
  	x.options.length = 0;
  	this.gateways.forEach((gateway) => {
  		const option = document.createElement("option");
  		// @ts-ignore
  		option.text = gateway.hwAddress.toString(16).toUpperCase();
  		// @ts-ignore
  		option.value = gateway.hwAddress;
  		// @ts-ignore
  		x.add(option);
  	});
	}

	async refresh() {
  	this.loading = true;
  	this.isGen3 = false;

  	try {
  		const hwAddress = (<HTMLInputElement>document.getElementById('hwAddress')).value;
  		const startDate = new Date((<HTMLInputElement>document.getElementById("startDate")).value);
  		const endDate = new Date((<HTMLInputElement>document.getElementById("endDate")).value);

  		if ( document.getElementById("batteryChartTemp") )
  		{
  			document.getElementById("batteryChartTemp").remove();
  		}

  		if ( document.getElementById("solarChartTemp") )
  		{
  			document.getElementById("solarChartTemp").remove();
  		}

  		if ( document.getElementById("powerChartTemp") )
  		{
  			document.getElementById("powerChartTemp").remove();
  		}

  		const batChartDiv = document.createElement("div");
  		batChartDiv.setAttribute( "id", "batteryChartTemp" );

  		const solChartDiv = document.createElement("div");
  		solChartDiv.setAttribute( "id", "solarChartTemp" );

  		let gatewayType = null;

  		this.gateways.forEach( ( gateway ) => {
  			if ( gateway.hwAddress === parseInt( hwAddress ) )
  			{
  				gatewayType = gateway.type;
  			}
  		} );

  		if ( gatewayType === "GW2000" )
  		{
  			this.isGen3 = true;
  			const powerChartDiv = document.createElement("div");
  			powerChartDiv.setAttribute( "id", "powerChartTemp" );
  			document.getElementById("powerChart").appendChild( powerChartDiv );

  			const gw2000_healths = await this.dipService.getGW2000PowerHealths( hwAddress, startDate, endDate );

  			const gw2000ChartConf = {
  				bindto: '#powerChartTemp',
  				data: {
  					xs: {},
  					columns: []
  				},
  				axis: {
  					x: {
  						type: 'timeseries',
  						tick: {
  							format: '%Y-%m-%d:%H-%M-%S'
  						}
  					}
  				},
  			};

  			const gw2000RawSampleChartConf = {
  				bindto: '#rawPowerChartTemp',
  				data: {
  					x: "x",
  					columns: []
  				},
  				axis: {
  					x: {
  						type: 'timeseries',
  						tick: {
  							format: '%Y-%m-%d:%H-%M-%S'
  						}
  					}
  				},
  			};

  			gw2000ChartConf.data.xs[`bat_${hwAddress}`] = `x_${hwAddress}`;

  			const lineData = [`bat_${hwAddress}`];
  			const axesData = [`x_${hwAddress}`];
  			let sum = 0;

  			const batPowerData = [`bat_power_${hwAddress}`];
  			const solarPowerData = [`solar_power_${hwAddress}`];
  			const sysPowerData = [`sys_power_${hwAddress}`];
  			const gwPowerData = [`gw_power_${hwAddress}`];
  			const powerAxesData = [`x`];

  			gw2000_healths.forEach( ( point ) => {
  				sum += point.accumulator_battery / ACC_UNITS_TO_WH_4mOHM;

  				// @ts-ignore
  				lineData.push( sum );
  				// lineData.push( point.accumulator_gateway );
            
  				// @ts-ignore
  				axesData.push( new Date( point.timestamp ) );

  				// @ts-ignore
  				powerAxesData.push( new Date( point.timestamp ));
  				batPowerData.push( ( parseInt( point.accumulator_battery ) / point.sample_count ).toString() );
  				solarPowerData.push( ( parseInt( point.accumulator_solar ) / point.sample_count ).toString() );
  				sysPowerData.push( ( parseInt( point.accumulator_system ) / ( 5 * point.sample_count ) ).toString() );
  				gwPowerData.push( ( parseInt( point.accumulator_gateway ) / ( 5 * point.sample_count ) ).toString() );
  			});

  			gw2000ChartConf.data.columns.push(lineData);
  			gw2000ChartConf.data.columns.push(axesData);

  			gw2000RawSampleChartConf.data.columns.push( batPowerData );
  			gw2000RawSampleChartConf.data.columns.push( powerAxesData );
  			gw2000RawSampleChartConf.data.columns.push( solarPowerData );
  			gw2000RawSampleChartConf.data.columns.push( sysPowerData );
  			gw2000RawSampleChartConf.data.columns.push( gwPowerData );

  			// @ts-ignore
  			c3.generate( gw2000ChartConf );
  			// @ts-ignore
  			c3.generate( gw2000RawSampleChartConf );
  		}

  		document.getElementById("batteryChart").appendChild( batChartDiv );
  		document.getElementById("solarChart").appendChild( solChartDiv );

  		const healths = await this.dipService.getPowerHealths( hwAddress, startDate, endDate );

  		const batChartConf = {
  			bindto: '#batteryChartTemp',
  			data: {
  				xs: {},
  				columns: []
  			},
  			axis: {
  				x: {
  					type: 'timeseries',
  					tick: {
  						format: '%Y-%m-%d:%H-%M-%S'
  					}
  				},
  				y: {
  					min: 2,
  					max: 5
  				}
  			},
  		};

  		const solChartConf = {
  			bindto: '#solarChartTemp',
  			data: {
  				xs: {},
  				columns: []
  			},
  			axis: {
  				x: {
  					type: 'timeseries',
  					tick: {
  						format: '%Y-%m-%d:%H-%M-%S'
  					}
  				},
  				y: {
  					min: 0,
  					max: 7
  				}
  			},
  		};

  		let isGen3System = false;
  		const data = {};

  		healths.forEach( ( health ) => {
  			if ( typeof data[ health.hw_address ] === typeof undefined )
  			{
  				data[ health.hw_address ] = [];
  			}

  			data[ health.hw_address ].push( health );
  		} );

  		for( const pm in data )
  		{
  			batChartConf.data.xs[`bat_${pm}`] = `x_${pm}`;

  			const lineData = [`bat_${pm}`];
  			const axesData = [`x_${pm}`];

  			data[pm].forEach( ( point ) => {
  				if( pm == hwAddress ) // Only true if GW2000
  				{
  					// @ts-ignore
  					lineData.push( point.battery_voltage * 2 );
  					isGen3System = true;
  				}
  				else
  				{
  					lineData.push( point.battery_voltage );
  				}
            
  				// @ts-ignore
  				axesData.push( new Date( point.timestamp ) );
  			});

  			batChartConf.data.columns.push(lineData);
  			batChartConf.data.columns.push(axesData);
  		}
  
  		for( const pm in data )
  		{
  			solChartConf.data.xs[`sol_${pm}`] = `x_${pm}`;

  			const lineData = [`sol_${pm}`];
  			const axesData = [`x_${pm}`];

  			data[pm].forEach( ( point ) => {
  				lineData.push( point.solar_voltage );
  				// @ts-ignore
  				axesData.push( new Date( point.timestamp ) );
  			});

  			solChartConf.data.columns.push(lineData);
  			solChartConf.data.columns.push(axesData);
  		}

  		if ( isGen3System )
  		{
  			batChartConf.axis.y.min = 5;
  			batChartConf.axis.y.max = 9;
  			solChartConf.axis.y.max = 15;
  		}

  		// @ts-ignore
  		c3.generate( batChartConf );
  		// @ts-ignore
  		c3.generate( solChartConf );
  	} catch ( err ) {
  		console.error( err );
  	}
    
  	this.loading = false;
	}
}
