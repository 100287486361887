<div class="grid">
    <div class="grid-main-view">
        <div style="overflow: auto; padding: 50px;">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let alert of alerts;">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{alert.type}}
                        </mat-panel-title>
                        <mat-panel-description>
                            {{alert.title}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
        
                    {{alert.body}}        
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>

    <div class="grid-side-bar">
        <button mat-raised-button color="primary" (click)="refresh()">REFRESH</button><br>
        Include Yarra Gen2: <input type="checkbox" [(ngModel)]="include_yarra_gen2"  (change)="refresh()"><br>
        Hide NO_CHECKIN: <input type="checkbox" [(ngModel)]="hide_no_checkins" (change)="refresh()"><br>
    </div>
</div>

<div *ngIf="loading" class="loading-overlay" >
    <mat-spinner mode="indeterminate" ></mat-spinner>
</div>